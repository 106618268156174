import images from 'assets/images/images'
import { capitalize } from 'lodash'
import { PopUpDataType } from 'types/userNotificationsType'
import { useEffect, useRef } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { BlocksRenderer } from '@strapi/blocks-react-renderer'
import { RootNode } from '@strapi/blocks-react-renderer/dist/BlocksRenderer'
import DCAImage from 'components/DCAImage'
import './popupModal.css'
import 'styles/modal/confirmModal.scss'

export type PopUpModalProps = {
  showModal: boolean
  popUpData: PopUpDataType
  handleClose: () => void
}

const PopUpModal = (props: PopUpModalProps): React.ReactElement => {
  const { handleClose, showModal, popUpData } = props

  const headline = popUpData?.headline ?? ''
  const cmsContent = JSON.parse(popUpData?.content ?? '') as RootNode[]
  const scrollRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const fadeRef = useRef<HTMLDivElement>(null)

  const updateFadeDisplay = (display: 'none' | 'block') => {
    if (fadeRef.current) {
      fadeRef.current.style.display = display
    }
  }

  useEffect(() => {
    const hideOnScroll = () => {
      if (scrollRef.current) {
        if (scrollRef.current.scrollTop > 2) {
          updateFadeDisplay('none')
        } else {
          updateFadeDisplay('block')
        }
      }
    }

    if (scrollRef.current && contentRef.current) {
      if (contentRef.current.clientHeight < scrollRef.current.clientHeight) {
        updateFadeDisplay('none')
      }
    }
    scrollRef.current?.addEventListener('scroll', hideOnScroll)
    return () => {
      scrollRef.current?.removeEventListener('scroll', hideOnScroll)
    }
  }, [])

  return (
    <Modal
      show={showModal}
      onEscapeKeyDown={(e) => {
        e.preventDefault()
      }}
      onHide={handleClose}
      backdrop='static'
    >
      <DCAImage
        id='onboarding-logo'
        className='ak-logo'
        alt='logo'
        src={images.logo}
        style={styles.imgLogo}
      />
      <Modal.Header style={styles.header}>
        <Modal.Title style={{ fontFamily: 'Clan Pro Medium' }}>{capitalize(headline)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='scrollable-div' ref={scrollRef}>
          <div ref={contentRef} className='user-notification'>
            <BlocksRenderer
              content={cmsContent}
              blocks={{
                image: ({ image }) => (
                  <img
                    className='rich-text-img'
                    style={{
                      objectFit: 'contain',
                    }}
                    src={image.url}
                    alt={image.alternativeText || 'image'}
                  />
                ),
                code: ({ children }) => (
                  <pre className='code-block rich-text-el'>
                    <code>{children}</code>
                  </pre>
                ),
                quote: ({ children }) => (
                  <blockquote className='quote-block rich-text-el'>{children}</blockquote>
                ),
                link: ({ children, url }) => (
                  <a href={url} target='_blank' rel="noreferrer">{children}</a>
                )
              }}
              modifiers={{
                code: ({ children }) => <code className='code-text rich-text-el'>{children}</code>,
              }}
            />
          </div>
        </div>
        <div className='fade-effect' ref={fadeRef} />
      </Modal.Body>
      <Modal.Footer style={styles.footer}>
        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default PopUpModal

const styles = {
  imgLogo: {
    margin: 10,
    alignSelf: 'center',
  },
  header: { border: 'none', alignSelf: 'center' },
  footer: { border: 'none', alignSelf: 'center' },
}
