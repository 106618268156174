import { FeatureBenefitsItemType } from 'types'
import { splitArrayIntoChunks } from 'config/utils/CommonFunction'
import { tr } from '../pdfHelper'
import { TranslationsPDFType } from '../types'

const getFeaturesAndBenefits = (
  featureBenefits: FeatureBenefitsItemType[],
  translations: TranslationsPDFType,
) => {
  if (!featureBenefits || !featureBenefits.length) {
    return ''
  }

  const imagesSecArr = featureBenefits.slice(0, 3)
  const listSecArr = featureBenefits.length >= 3 ? featureBenefits.slice(3) : []
  const newListSecArr = listSecArr.length ? splitArrayIntoChunks(listSecArr, 2) : []

  const extractedImg = imagesSecArr.map((item) => {
    const fullImage = item.images?.find((image) => image.type === 'full')
    return { url: fullImage?.url || '' }
  })

  const imagesSecStr = imagesSecArr
    .map((item, index) => {
      const desc = item.benefits.join(' ')
      return `<div style="display: flex; flex-direction: column; padding-right: 10px; flex: 1;">
        ${
          extractedImg[index]?.url &&
          `<img style="object-fit: cover; width: 100%; height: 235px;" src="${extractedImg[index]?.url}">`
        } 
        <div class="small" style="padding-left: 5px; padding-right: 5px; overflow-wrap: break-word;">
          <p class="bold"> ${item.feature}</p>
          <p class="small"> ${desc}</p>
        </div>
      </div>`
    })
    .join('')

  const listSecStr = newListSecArr
    .map((listArr) => {
      const listItems = listArr
        .map((listItem) => {
          const benefits = listItem.benefits.map((item) => `<li>${item}</li>`).join('')
          return `<div class="detail-card">
        <p class="bold small">${listItem.feature}</p>
        <ul style="font-size: 10px; list-style-type: square; padding: 0px 20px;">
          ${benefits}
        </ul>
      </div>`
        })
        .join('')
      return `<div class="feature-benefit-sec-2">${listItems}</div>`
    })
    .join('')

  return `<div id="Features_and_Benefits" class="feature-benefit-sec-1">
    <h4>${tr(translations, 'feature_and_benefits', 'Feature and Benefits').toLocaleUpperCase()}</h4>
    <div class="line"></div>
    <div class="row" style="justify-content: space-between;padding: 0px;">
      ${imagesSecStr}
    </div>
  </div>
  ${listSecStr}`
}

export default getFeaturesAndBenefits
