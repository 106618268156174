import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { MAX_PRODUCTS_COMPARE } from 'config/constants'
import { createMigrate, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { comparisonMigrations } from './migration'
import { ComparisonStateV1 } from './type'

const initialState: ComparisonStateV1 = {
  productCompare: [],
}

const comparisonSlice = createSlice({
  name: 'comparison',
  initialState,
  reducers: {
    setComparisonList(state, action: PayloadAction<number[]>) {
      state.productCompare = action.payload
    },
    addProductsToCompareBucket(state, action: PayloadAction<number[]>) {
      const combined = [...state.productCompare, ...action.payload]
      state.productCompare = Array.from(new Set(combined)).slice(0, MAX_PRODUCTS_COMPARE)
    },
    removeProductFromCompareBucket(state, action: PayloadAction<number>) {
      state.productCompare = state.productCompare.filter((product) => product !== action.payload)
    },
    addProductToCompareBucket(state, action: PayloadAction<number>) {
      if (
        !state.productCompare.includes(action.payload) &&
        state.productCompare.length < MAX_PRODUCTS_COMPARE
      ) {
        state.productCompare.push(action.payload)
      }
    },
    resetComparison() {
      return initialState
    },
  },
})

export const {
  resetComparison,
  addProductsToCompareBucket,
  removeProductFromCompareBucket,
  addProductToCompareBucket,
  setComparisonList,
} = comparisonSlice.actions

const comparisonPersistConfig = {
  key: 'comparison',
  version: 1,
  storage,
  migrate: createMigrate(comparisonMigrations, { debug: false }),
}

export default persistReducer(comparisonPersistConfig, comparisonSlice.reducer)
