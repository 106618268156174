import { HeadlineDataType } from 'components/Header/Header'
import { AppDataKeyEnum, GET_APP_DATA } from 'config/libs/globals'
import { getBusinessHeadlineDb } from 'database/queries/country'
import useOnboardingTour from 'features/Onboarding/hooks/useOnboarding'
import useNavigation from 'hooks/useNavigation'
import { Button, Card, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { selectTranslations } from 'redux/selector'

const OnboardingBlock = () => {
  const translations = useSelector(selectTranslations)

  const { onDynamicNavigate } = useNavigation()
  const { runOnboardingTour } = useOnboardingTour()

  const play = translations.play ?? 'Play'
  const watchOnboardingAgain =
    translations.watch_the_onboarding_again ?? 'Watch the onboarding again'
  const onboardingIntroductionCaption =
    translations.settings_introduction_caption ??
    ' Here you can find the introduction and explanation of the different app functions'

  const onRunOnboarding = async () => {
    let headlineData = GET_APP_DATA(AppDataKeyEnum.businessHeadline) as HeadlineDataType
    if (!headlineData) {
      headlineData = (await getBusinessHeadlineDb()) as HeadlineDataType
    }
    onDynamicNavigate(headlineData?.pageId)
    runOnboardingTour()
  }

  return (
    <Card className='onboarding-block setting-card'>
      <Row>
        <h6 className='settings-heading'>
          <i className='karcher-icon settings-icons'>&#xe802;</i>
          {watchOnboardingAgain}
        </h6>

        <p className='settings-caption'>{onboardingIntroductionCaption}</p>
      </Row>

      <div className='mb-2'>
      <Button
        id='onboarding-btn-show-onboarding'
        className='app-button btn-play-onboarding'
        onClick={onRunOnboarding}
        disabled
      >
        {play.toUpperCase()}
      </Button>
      </div>
      
    </Card>
  )
}

export default OnboardingBlock
