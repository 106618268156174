import { publicClient } from 'services/client'
import { GetProductListByRootlineResponse } from 'services/types'
import { API_YEAR } from 'config/constants'
import { getCurrentCountry, getCurrentLanguage } from 'config/utils/CommonFunction'
import {
  GetProductListByRootlineDocument,
  GetProductListByRootlineQuery,
} from './productListRootLine.generated'

const fetchCurrentCountryAndLanguage = async () => {
  const currentCountry = await getCurrentCountry()
  const currentLanguage = await getCurrentLanguage()
  return { currentCountry, currentLanguage }
}

export const getProductListByRootLine = async (
  productGroupIds: number[],
): Promise<GetProductListByRootlineResponse> => {
  try {
    const { currentCountry, currentLanguage } = await fetchCurrentCountryAndLanguage()
    const { data } = await publicClient.query<GetProductListByRootlineQuery>({
      query: GetProductListByRootlineDocument,
      variables: {
        input: {
          rootLineIds: productGroupIds,
          languageCode: currentLanguage.languageCode,
          countryCode: currentCountry.code,
          year: API_YEAR,
        },
        pagination: {
          limit: 1000,
        },
      },
    })

    if (!data || !data.getProductListByRootline) {
      throw new Error('data is undefined')
    }

    return data.getProductListByRootline
  } catch (error) {
    console.error('Error getProductListByRootLine:', error)
    throw new Error('Failed getProductListByRootLine')
  }
}
