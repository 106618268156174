import { Card, Col, Container, Row } from 'react-bootstrap'
import { extractAnchorsFromText } from 'config/libs/helpers'
import { ImageHeadlineFourColumnsType } from 'types'
import DCAImage from 'components/DCAImage'

type ImageHeadlineFourColumnProps = {
  layoutData: ImageHeadlineFourColumnsType
}

type RenderItemProps = {
  image?: string
  headline?: string
  text?: string
}

const ImageHeadlineFourColumns = ({ layoutData }: ImageHeadlineFourColumnProps) => {
  const renderItem = ({ image, headline, text }: RenderItemProps) => {
    const textWithAnchors = extractAnchorsFromText(text ?? '')

    return (
      <Card className='card-wrapper-no-border px-4'>
        {image && <DCAImage src={image} type='cardImage' />}
        {headline && <h5 className='ak-bold mt-3'>{headline}</h5>}
        {textWithAnchors && <div dangerouslySetInnerHTML={{ __html: textWithAnchors }} />}
      </Card>
    )
  }

  return (
    <Container className='image-headline-four-columns pt-4'>
      <Row>
        <Col md={3} lg={3}>
          {renderItem({
            image: layoutData.image_first,
            headline: layoutData.headline_first,
            text: layoutData.text_first,
          })}
        </Col>

        <Col md={3} lg={3}>
          {renderItem({
            image: layoutData.image_second,
            headline: layoutData.headline_second,
            text: layoutData.text_second,
          })}
        </Col>

        <Col md={3} lg={3}>
          {renderItem({
            image: layoutData.image_third,
            headline: layoutData.headline_third,
            text: layoutData.text_third,
          })}
        </Col>

        <Col md={3} lg={3}>
          {renderItem({
            image: layoutData.image_fourth,
            headline: layoutData.headline_fourth,
            text: layoutData.text_fourth,
          })}
        </Col>
      </Row>
    </Container>
  )
}

export default ImageHeadlineFourColumns
