import { replaceSlashByDoubleQuote } from 'helper/common'
import { title } from 'process'
import { Bucket, SearchQueryDataKey, SearchResponseType } from 'types/searchType'
import { useEffect, useState } from 'react'
import { Container, Nav, Row, Tab } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PAGE_NAMES, PAGE_TYPES } from 'config/constants'
import { formatAndSymbol } from 'config/libs/helpers'
import { selectTranslations } from 'redux/selector'
import useAnalytics from 'hooks/useAnalytics'
import {  useHeaderContext } from 'components/Page/headerContext'
import SearchTab from '../components/SearchTab'
import 'styles/pages/searchScreen.scss'

type SearchScreenUIProps = {
  data: SearchResponseType
  searchKey?: string
}

function orderBuckets(obj: SearchResponseType | undefined): SearchResponseType {
  if (!obj) return {} as SearchResponseType

  const orderedKeys = Object.values(SearchQueryDataKey)

  return orderedKeys.reduce((acc, key) => {
    if (obj[key]) {
      acc[key] = obj[key]
    }

    return acc
  }, {} as SearchResponseType)
}

const TITLE_MAPPING: { [key: string]: string } = {
  hg: 'home_garden',
  pro: 'professional',
  accessories: 'accessories',
  detergents: 'detergents',
  content: 'content',
  old: 'old_machines',
}

const SearchScreenUI = ({ data, searchKey }: SearchScreenUIProps) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const activeTab = searchParams.get('activeTab')
  const { headerHeight } = useHeaderContext()

  const translations = useSelector(selectTranslations)
  const { setPageInfoAnalytics, setSearchAnalytics } = useAnalytics()

  const resultFor = translations?.results_for ?? 'Results for'

  const orderedData = orderBuckets(data)
  const totalItems = Object.values(orderedData).reduce(
    (total, bucket) => total + bucket.docCount,
    0,
  )

  const [activeTabKey, setActiveTabKey] = useState<string | null>(
    activeTab !== null ? activeTab : Object.keys(orderedData)[0],
  )

  useEffect(() => {
    setActiveTabKey(activeTab !== null ? activeTab : Object.keys(orderedData)[0])
  }, [activeTab, orderedData])

  /** Analytics */
  useEffect(() => {
    if (data) {
      const totalItems = Object.values(data).reduce((total, bucket) => total + bucket.docCount, 0)
      setSearchAnalytics({ searchTerm: searchKey, searchTotalResult: totalItems })
    }
    setPageInfoAnalytics({
      pageName: PAGE_NAMES.SEARCH,
      pageType: PAGE_TYPES.SEARCH,
    })
  }, [data])

  const onTabClick = (event: string | null) => {
    if (!event || !searchKey) return
    navigate(`/search/${encodeURIComponent(searchKey)}?activeTab=${event}`)
  }

  const renderTab = (key: string, value: Bucket) => (
    <Tab.Pane key={key} eventKey={key}>
      <SearchTab tabKey={TITLE_MAPPING[key]} tabData={value} />
    </Tab.Pane>
  )
  
  return (
    <Container className='search-screen-container pb-2'>
      {searchKey && (
        <h5 className='search-screen-title'>{`${totalItems} ${resultFor} "${replaceSlashByDoubleQuote(
          searchKey,
        ).toUpperCase()}"`}</h5>
      )}

      <Tab.Container defaultActiveKey={activeTabKey ?? 1} onSelect={onTabClick}>
        <Row className='ms-0' style={{ position: 'sticky', zIndex: 999, top: headerHeight }}>
          <Nav variant='pills' className='flex-row'>
            {Object.entries(orderedData).map(([key]) => {
              const title = translations?.[TITLE_MAPPING[key]] ?? key
              const docCount = orderedData[key].docCount;
              return (
                <Nav.Item key={key} className='search-tab-item'>
                  <Nav.Link eventKey={key}>{formatAndSymbol(title.toUpperCase()) + ' ('+docCount+')'}</Nav.Link>
                </Nav.Item>
              )
            })}
          </Nav>
        </Row>

        <Tab.Content>
          {Object.entries(orderedData).map(([key, value]) => renderTab(key, value))}
        </Tab.Content>
      </Tab.Container>
    </Container>
  )
}

export default SearchScreenUI
