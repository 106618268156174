import { getProductListDbByRootLine } from 'database/queries/product'
import { getProductListByRootLine } from 'services/productListRootLine/productListRootline.custom'
import { FilterableItems, GetProductListByRootlineResponse, Maybe, Product } from 'services/types'
import { useState } from 'react'
import { Container } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { ProductGroupDataType } from 'types'
import { getPageContentWithCallback } from 'redux/reducers/pageContentReducer'
import useDataFetcher from 'hooks/useDataFetcher'
import useNavigation from 'hooks/useNavigation'
import { useGlobalModalContext } from 'features/Modal/GlobalModal'
import DropdownProducts from './DropdownProducts'
import GroupListFilter, { AdditionalFiltersType } from './GroupListFilter'
import { filterAdditionalProduct } from './groupListDropdown.utils'

type GroupListDropdownProps = {
  dropdownData: ProductGroupDataType[]
  productGroupId: number
  onProductListUpdated?: (productList: Maybe<Product>[]) => void
}

const GroupListDropdown = ({
  dropdownData,
  productGroupId,
  onProductListUpdated,
}: GroupListDropdownProps) => {
  const { showModal, hideModal } = useGlobalModalContext()
  const { onDynamicNavigate } = useNavigation()

  const { fetchData } = useDataFetcher()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [productList, setProductList] = useState<Maybe<Product>[]>([])
  const [filterableItems, setFilterableItems] = useState<Maybe<FilterableItems>>({})

  const dispatch = useDispatch()

  const handleProductListUpdate = (
    productListData: Maybe<Product>[],
    additionalSelected?: AdditionalFiltersType,
  ) => {
    const productListFilter = filterAdditionalProduct(productListData, additionalSelected)
    setProductList(productListFilter)
    if (onProductListUpdated) {
      onProductListUpdated(productListData)
    }
  }

  const fetchProductListByRootLine = async (
    productGroupIds: number[],
    additionalSelected?: AdditionalFiltersType,
  ) => {
    try {
      setIsLoading(true)
      const productList = await fetchData<number[], GetProductListByRootlineResponse>(
        getProductListByRootLine,
        getProductListDbByRootLine,
        productGroupIds,
        productGroupIds,
      )
      if (productList && productList.data) {
        handleProductListUpdate(productList.data, additionalSelected)
        setFilterableItems(productList.filterableItems ?? {})
      }
    } catch (error) {
      console.error('fetchProductList in GroupListDropdown error', error)
    } finally {
      setIsLoading(false)
    }
  }

  const onFiltersUpdate = (
    categorySelected?: ProductGroupDataType[],
    additionalSelected?: AdditionalFiltersType,
  ) => {
    if (!categorySelected || categorySelected.length === 0) {
      setProductList([])
      setFilterableItems({})
      return
    }

    const categoryIds = [
      productGroupId,
      ...(categorySelected ? categorySelected.map((category) => category.id) : []),
    ]
    fetchProductListByRootLine(categoryIds, additionalSelected)
  }

  const onGoNextPage = (nextPageId: number) => {
    if (!nextPageId) return

    onDynamicNavigate(nextPageId)
  }

  // TODO: bad code
  const onAddProductToFavorite = ({ id, name }: { id: number; name: string }) => {
    return new Promise<void>((resolve, reject) =>
      dispatch(
        getPageContentWithCallback({
          pageId: id,
          onSuccess(pageContent) {
            showModal('FAVORITES_MODAL', {
              showModal: true,
              itemName: name,
              pageContent: pageContent,
              handleClose: hideModal,
            })
            resolve()
          },
          onFailure() {
            reject()
          },
        }),
      ),
    )
  }

  return (
    <Container className='mt-4 group-list-dropdown'>
      <GroupListFilter
        productGroupId={productGroupId}
        categories={dropdownData}
        filterableItems={filterableItems}
        onFiltersUpdate={onFiltersUpdate}
      />

      <DropdownProducts
        isLoading={isLoading}
        productList={productList}
        onAddProductToFavorite={onAddProductToFavorite}
        onGoNextPage={onGoNextPage}
      />
    </Container>
  )
}

export default GroupListDropdown
