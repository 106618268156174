import { getProductDetailDbById } from 'database/queries/product'
import { getProductDetailQuery } from 'services'
import { useEffect, useState } from 'react'
import { Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { ProductDetailItemType, ProductDetailResponseType, TextItemType } from 'types'
import { TRANSLATIONS } from 'config/constants'
import {
  getEquipmentTable,
  getShortDescriptionTable,
  getTechDataTable,
} from 'config/utils/ComparisonFunction'
import { selectTranslations } from 'redux/selector'
import useDataFetcher from 'hooks/useDataFetcher'
import DescriptionCompare from 'features/Comparison/components/DescriptionCompare'
import EquipmentCompare from 'features/Comparison/components/EquipmentCompare'
import ImagesSection from 'features/Comparison/components/ImagesSection'
import TechDataCompare from 'features/Comparison/components/TechDataCompare'
import DividerPDF from '../components/DividerPDF'
import HeaderPDF from '../components/HeaderPDF'
import { selectProductComparison } from 'redux/comparison/selector'

export type ComparisonPDFType = {
  onDownloadStatus: (status: boolean) => void
}

const ComparisonPDF = ({ onDownloadStatus }: ComparisonPDFType) => {
  const translations = useSelector(selectTranslations)
  const productCompare = useSelector(selectProductComparison)
  const [productCompareDetails, setProductCompareDetails] = useState<
    ProductDetailItemType[] | null
  >(null)
  const { fetchData } = useDataFetcher()

  const productComparison = translations.product_comparison ?? TRANSLATIONS.productComparison

  useEffect(() => {
    const getProductCompareDetails = async () => {
      try {
        const productDetailResponses = await Promise.all(
          productCompare.map(async (product) => {
            const productDetailResponse = await fetchData<number, ProductDetailResponseType>(
              getProductDetailQuery,
              getProductDetailDbById,
              Number(product),
              Number(product),
            )
            return productDetailResponse
          }),
        )

        const productDetails = productDetailResponses.map((response) => {
          if (response?.data) {
            return JSON.parse(response?.data ?? '{}')
          } else {
            throw new Error('Error fetching product details')
          }
        })

        setProductCompareDetails(productDetails)
      } catch (error) {
        console.error('getProductCompareDetails', error)
      }
    }

    if (productCompare?.length > 0) {
      getProductCompareDetails()
    }
  }, [productCompare])

  if (!productCompareDetails) return null

  onDownloadStatus(true)

  const compareListTechdata = productCompareDetails.map((item) => item.techdata)
  const equipmentsCompareData = productCompareDetails.map((item) => item.equipment)
  const descriptionsData: TextItemType[] = productCompareDetails
    .map((item) => item.texts.find((text) => text.type === 'short'))
    .filter((text): text is TextItemType => Boolean(text))
  const techDataTable = getTechDataTable(compareListTechdata)
  const equipmentsTable = getEquipmentTable(equipmentsCompareData)
  const descriptionTable = getShortDescriptionTable(descriptionsData)

  return (
    <>
      <HeaderPDF />

      <DividerPDF />

      <Row className='d-flex align-items-center'>
        <h1 className='ak-bold mt-3'>{productComparison.toUpperCase()}</h1>
      </Row>

      <ImagesSection productCompareDetails={productCompareDetails} />

      <TechDataCompare techDataTable={techDataTable} />

      <EquipmentCompare equipmentsTable={equipmentsTable} />

      <DescriptionCompare descriptionTable={descriptionTable} />
    </>
  )
}

export default ComparisonPDF
