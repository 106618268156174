import { syncOldProductFromDexieToRedux } from 'database/migrations/migrateDexieToRedux'
import {
  useGetProductComparisonLazyQuery,
  useSaveProductComparisonMutation,
} from 'services/comparison/comparison.generated'
import { Product } from 'services/types'
import { getDefaultClientParams } from 'services/util'
import { SearchItem } from 'types/searchType'
import { useDispatch, useSelector } from 'react-redux'
import { AccessoryItemType, ProductDetailItemType, ProductListItemType } from 'types'
import { productCompareAnalytics } from 'config/adobeAnalytics/analytics'
import { MAX_PRODUCTS_COMPARE } from 'config/constants'
import { showAlert } from 'config/utils/CommonFunction'
import { setComparisonList } from 'redux/comparison/reducer'
import { selectProductComparison } from 'redux/comparison/selector'
import { selectAppOnline } from 'redux/selector'
import { selectUser } from 'redux/user/user.selector'
import { useGlobalModalContext } from 'features/Modal/GlobalModal'

export type ProductComparisonData =
  | ProductListItemType
  | ProductDetailItemType
  | AccessoryItemType
  | SearchItem
  | Product

export const useProductComparison = () => {
  const dispatch = useDispatch()
  const productCompare = useSelector(selectProductComparison)
  const { showModal, hideModal } = useGlobalModalContext()
  const [getProductList] = useGetProductComparisonLazyQuery( {
    fetchPolicy: 'network-only',
  })
  const [saveComparison] = useSaveProductComparisonMutation()
  const isOnline = useSelector(selectAppOnline)
  const user = useSelector(selectUser)

  const syncProductFromServerToLocal = async (serverProductIds: (number | null)[]) => {
    dispatch(setComparisonList(serverProductIds.filter((id) => id !== null) as number[]))
  }

  const syncProductFromLocalToServer = async (localProductIds: number[]) => {
    if (!user?.userId || !isOnline) return
    try {
      const localeInfo = await getDefaultClientParams()
      await saveComparison({
        variables: {
          input: {
            productIds: localProductIds,
            ...localeInfo,
          },
        },
      })
    } catch (error) {
      console.log('error sync comparison data', error)
    }
  }

  const syncComparisonBetweenLocalAndServer = async () => {
    try {
      await syncOldProductFromDexieToRedux(dispatch)

      if (!user?.userId) return
      if (!isOnline) return

      const localeInfo = await getDefaultClientParams()

      const { data } = await getProductList({
        variables: {
          input: {
            ...localeInfo,
          },
        },
      })

      const serverProductIds = data?.getProductComparison?.productIds || []
      // compare data from server with local data
      const localProductIds = productCompare || []
      if (localProductIds.length > 0 && serverProductIds.length === 0) {
        syncProductFromLocalToServer(localProductIds)
      } else if (localProductIds.length === 0 && serverProductIds.length > 0) {
        syncProductFromServerToLocal(serverProductIds.filter((id) => id !== null))
      } else {
        // if data is different
        if (
          !isComparisonDataMatched(
            serverProductIds.filter((id) => id !== null) as number[],
            localProductIds,
          )
        ) {
          // merge data
          showModal('OPTION_MODAL', {
            heading: 'Comparison data is different is different',
            message: 'Please choose the source of data',
            leftButtonText: 'Use local data',
            rightButtonText: 'Use server data',
            showModal: true,
            onLeftClick() {
              syncProductFromLocalToServer(localProductIds)
            },
            onRightClick() {
              syncProductFromServerToLocal(serverProductIds)
            },
            handleClose: hideModal,
          })
        }
      }
    } catch (error) {
      console.log('error sync comparison data', error)
    }
  }

  const deleteProductFromComparison = async (productId: number) => {
    try {
      const newProduct = productCompare.filter((id) => id !== productId)
      dispatch(setComparisonList(newProduct))
      productCompareAnalytics(newProduct)
      syncProductFromLocalToServer(newProduct)
    } catch (error) {
      showAlert('Error deleting product from comparison')
    }
  }

  const addProductToComparison = async (productId: number, productData?: ProductComparisonData) => {
    try {
      const combined = [...productCompare, productId]
      const newProduct = Array.from(new Set(combined)).slice(0, MAX_PRODUCTS_COMPARE)
      dispatch(setComparisonList(newProduct))
      productCompareAnalytics(newProduct)
      showModal('COMPARE_MODAL', {
        showModal: true,
        productData: productData,
        handleClose: hideModal,
      })
      syncProductFromLocalToServer(newProduct)
    } catch (error) {
      showAlert('Error adding product to comparison')
    }
  }

  const onAddOrRemoveProductCompare = async (productData: ProductComparisonData) => {
    const productId = Number(productData.id)
    const isProductExisted = !!productCompare.find((id) => id === productId)
    if (isProductExisted) {
      return deleteProductFromComparison(productId)
    } else {
      if (productCompare.length >= MAX_PRODUCTS_COMPARE) {
        showAlert(`You can only compare ${MAX_PRODUCTS_COMPARE} products at a time`)
      } else {
        return addProductToComparison(productId, productData)
      }
    }
  }

  return {
    syncComparisonBetweenLocalAndServer,
    onAddOrRemoveProductCompare,
    deleteProductFromComparison,
    addProductToComparison,
  }
}

const isComparisonDataMatched = (serverProductIds: number[], localProductIds: number[]) => {
  if (serverProductIds.length !== localProductIds.length) return false
  for (const id of serverProductIds) {
    if (!localProductIds.includes(id)) return false
  }
  return true
}
