import { User } from 'assets/svg'
import throttle from 'lodash/debounce'
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from 'redux/user/user.selector'
import Login from './Login'
import UserProfile from './UserProfile'

const UserProfileButton = () => {
  const [detailVisible, setDetailVisible] = React.useState(false)
  const user = useSelector(selectUser)
  const isLogged = !!user

  const onDelayHover = useCallback(
    throttle((state) => {
      setDetailVisible(state)
    }, 300),
    [],
  )

  return (
    <div
      onMouseEnter={() => {
        onDelayHover(true)
      }}
      onMouseLeave={() => onDelayHover(false)}
      style={{ position: 'relative', zIndex: 10 }}
    >
      <div className='no-select cursor-pointer'>
        <User />
        {isLogged && <div id='online-dot' />}
      </div>
      {detailVisible && (
        <div
          onMouseEnter={() => onDelayHover(true)}
          onMouseLeave={() => onDelayHover(false)}
          className='login'
          id='login-wrapper'
        >
          {isLogged ? <UserProfile /> : <Login />}
        </div>
      )}
    </div>
  )
}
export default UserProfileButton
