import { getImageUrl } from 'helper/common'
import { toast } from 'react-toastify'
import { Product } from 'services/types'
import React, { useState } from 'react'
import { Card, Col, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { faCopy } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ProductListItemType } from 'types'
import { TRANSLATIONS } from 'config/constants'
import { selectTranslations } from 'redux/selector'
import ComparisonButton from 'components/ComparisonButton'
import DCAImage from 'components/DCAImage'
import 'styles/pages/productList.scss'

type UIProps = {
  product: ProductListItemType | Product
  onClickFavorite: () => Promise<void>
  onClickProduct: () => void
}

const ProductItem = ({ product, onClickFavorite, onClickProduct }: UIProps) => {
  const [favoriteLoading, setFavoriteLoading] = useState(false)
  const translations = useSelector(selectTranslations)
  const { id, name, texts, partnumberFormatted, priceNetFormatted, priceGrossFormatted } = product

  const imageUrl = getImageUrl(product)

  const LABELS = {
    copied: translations.copied || TRANSLATIONS.copied,
    orderNumber: translations.order_number || 'Part Number',
    netPriceNumber: translations.net_priceNumber || 'Price Net',
    grossPriceNumber: translations.gross_priceNumber || 'Price Gross',
    addToFavorite: translations.add_to_fav || 'Add to favorites',
  }

  const onCopy = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    navigator.clipboard.writeText(partnumberFormatted ?? '')
    toast(LABELS.copied, { autoClose: 1000 })
    event.stopPropagation()
  }

  const handleClickFavorite = async () => {
    setFavoriteLoading(true)
    try {
      await onClickFavorite()
    } catch (error) {
      console.error('Error while adding to favorite', error)
    }
    setFavoriteLoading(false)
  }

  return (
    <Col className='product-item-ui'>
      <ComparisonButton data={product} />
      <Card key={id} className='card-wrapper'>
        <span
          tabIndex={0}
          role='button'
          className='favorite-form'
          onClick={(event) => {
            event.stopPropagation()
            handleClickFavorite()
          }}
        >
          <div className='horizontal-p'>
            {favoriteLoading ? (
              <Spinner className='favorite-icon' />
            ) : (
              <p className='karcher-icon favorite-icon'>&#xe843;</p>
            )}
            <p className='favorite-text'>{LABELS.addToFavorite}</p>
          </div>
        </span>

        <div className='export-text cursor-pointer' onClick={onClickProduct}>
          <DCAImage type='lazyImage' className='ak-card-img' src={imageUrl} />
        </div>
        <div className='text-left pt-3 cursor-pointer' onClick={onClickProduct}>
          <p className='left-p'>{name}</p>

          <div className='product-price'>
            {LABELS.orderNumber} {`${partnumberFormatted}`}
            <FontAwesomeIcon className='copy-icon' icon={faCopy} onClick={onCopy} />
          </div>

          {priceNetFormatted && (
            <div className='product-price'>
              {LABELS.netPriceNumber} {`${priceNetFormatted}`}
            </div>
          )}

          {priceGrossFormatted && (
            <div className='product-price'>
              {LABELS.grossPriceNumber} {`${priceGrossFormatted}`}
            </div>
          )}

          {texts && <div className='product-desc'>{texts[0]?.value}</div>}
        </div>
      </Card>
    </Col>
  )
}

export default ProductItem
