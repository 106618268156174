import { getCurrentCountry, getCurrentLanguage } from 'config/utils/CommonFunction'

export const getDefaultClientParams = async () => {
  const currentCountry = await getCurrentCountry()
  const currentLanguage = await getCurrentLanguage()

  return {
    countryCode: currentCountry.code,
    languageCode: currentLanguage.languageCode,
  }
}
