import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ProductDetailItemType } from 'types'
import { PAGE_IDS, PAGE_NAMES, PAGE_TYPES, TRANSLATIONS } from 'config/constants'
import { selectProductComparison } from 'redux/comparison/selector'
import { selectTranslations } from 'redux/selector'
import useAnalytics from 'hooks/useAnalytics'
import LoadingPage from 'features/Common/screens/LoadingPage'
import { useProduct } from 'features/Product/hooks/useProduct'
import Page from 'components/Page/Page'
import { useProductComparison } from '../hooks/useProductComparison'
import ComparisonUI from './Comparison.ui'
import 'styles/pages/comparison.scss'

const Comparison = () => {
  const translations = useSelector(selectTranslations)
  const productCompare = useSelector(selectProductComparison)
  const { setPageInfoAnalytics } = useAnalytics()
  const { onGetProductDetails } = useProduct()

  const { syncComparisonBetweenLocalAndServer } = useProductComparison()

  useEffect(() => {
    syncComparisonBetweenLocalAndServer()
  }, [])
  
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [productCompareDetails, setProductCompareDetails] = useState<ProductDetailItemType[]>([])

  const productComparison = translations.product_comparison ?? TRANSLATIONS.productComparison

  useEffect(() => {
    setPageInfoAnalytics({
      pageName: PAGE_NAMES.COMPARISON,
      pageType: PAGE_TYPES.COMPARISON,
    })
  }, [])

  useEffect(() => {
    const getProductCompareDetails = async () => {
      try {
        const productDetailsCompare = await onGetProductDetails(productCompare)

        setProductCompareDetails(productDetailsCompare)
      } catch (error) {
        console.error('getProductCompareDetails', error)
      } finally {
        setIsLoading(false)
      }
    }

    if (!productCompare || productCompare.length === 0) {
      setIsLoading(false)
      setProductCompareDetails([])
      return
    }

    getProductCompareDetails()
  }, [productCompare])

  const renderContent = () => {
    if (isLoading) {
      return <LoadingPage />
    }
    return <ComparisonUI productCompareDetails={productCompareDetails} />
  }

  return (
    <Page
      pageId={PAGE_IDS.COMPARISON_SCREEN}
      breadCrumbTitle={productComparison}
      className='comparison-wrapper'
    >
      {renderContent()}
    </Page>
  )
}

export default Comparison
