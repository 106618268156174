import { createSlice } from '@reduxjs/toolkit'
import { UserState } from './user.type'

const USER_STATE: UserState = {
  data: undefined,
}

export const userSlice = createSlice({
  name: 'user',
  initialState: USER_STATE,
  reducers: {
    setUser: (state, action) => {
      state.data = action.payload
    },
    resetUser: (state) => {
      state.data = undefined
    },
  },
})


export const { setUser, resetUser } = userSlice.actions
export default userSlice.reducer