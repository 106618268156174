import { getCurrentCountryDb, getCurrentLanguageDb } from 'database/queries/country'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getStructureAction } from 'redux/action/structureAction'
import { getTranslationAction } from 'redux/action/translationAction'
import { getAnalyticsAvailableRequest } from 'redux/reducers/analyticsReducer'
import {
  getCountries,
  setCurrentCountry,
  setCurrentLanguage,
} from 'redux/reducers/countriesReducer'
import { getProductFinderAvailability } from 'redux/reducers/productFinderReducer'
import {
  selectAppOnline,
  selectCurrentCountry,
  selectIsAdobeAnalyticsAvailable,
} from 'redux/selector'
import { useProductComparison } from 'features/Comparison/hooks/useProductComparison'
import { useFavorites } from 'features/Favorites/hooks/useFavorites'
import useOnboardingTour from 'features/Onboarding/hooks/useOnboarding'
import { useConnectionHandler } from 'components/ConnectionHandler'
import useAnalytics from './useAnalytics'

type ReturnType = {
  loading: boolean
  fetchDataInitialize: () => void
}

/**
 * useAppDataInit is a custom hook that initializes the app data.
 *
 * This hook will check if the current country is set in the local database.
 * If yes it will set the current country in the redux store and call the APIs.
 * If not it will redirect the user to the country selection page (handle by Routes.tsx).
 *
 * The hook returns a loading state and a fetchDataInitialize function.
 *
 * @returns {object} An object containing the loading state and fetchDataInitialize function.
 */
const useAppDataInit = (): ReturnType => {
  const dispatch = useDispatch()
  const { onLoadFavorites } = useFavorites()
  const { initAdobeAnalytics } = useAnalytics()
  const { loadOnboardingData } = useOnboardingTour()
  const isConnectionInitialize = useConnectionHandler()
  const currentCountry = useSelector(selectCurrentCountry)

  const isAdobeAvailable = useSelector(selectIsAdobeAnalyticsAvailable)
  const isAppOnline = useSelector(selectAppOnline)

  const [loading, setLoading] = useState<boolean>(true)

  const fetchDataInitialize = async () => {
    try {
      await Promise.all([getTranslationAction(dispatch), getStructureAction(dispatch)])
      if (isAppOnline) {
        await Promise.all([
          dispatch(getProductFinderAvailability()),
          dispatch(getAnalyticsAvailableRequest()),
        ])
      }
      onLoadFavorites()
      loadOnboardingData()
    } catch (error) {
      console.error('Error initializing app data', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (isConnectionInitialize && currentCountry) {
      fetchDataInitialize()
    }
  }, [isConnectionInitialize, currentCountry])

  /**
   * Set current country and language from local database.
   * Call the APIs don't need country and language.
   */
  useEffect(() => {
    if (isConnectionInitialize) {
      const init = async () => {
        dispatch(getCountries())
        const currentCountryDB = await getCurrentCountryDb()
        const currentLanguageDB = await getCurrentLanguageDb()

        if (currentCountryDB && currentLanguageDB) {
          dispatch(setCurrentCountry(currentCountryDB))
          dispatch(setCurrentLanguage(currentLanguageDB))
        } else {
          setLoading(false)
        }
      }

      init()
    }
  }, [isConnectionInitialize])

  /**
   * Initialize Adobe Analytics when the country available
   */
  useEffect(() => {
    if (isAdobeAvailable && isAppOnline) {
      initAdobeAnalytics()
    }
  }, [isAdobeAvailable, isAppOnline])

  return { loading, fetchDataInitialize }
}

export default useAppDataInit
