import { ProductDetailItemType } from 'types'
import { tr } from '../pdfHelper'
import { TranslationsPDFType } from '../types'

const getTechData = (productData: ProductDetailItemType, translations: TranslationsPDFType) => {
  if (!productData.techdata || !productData.techdata.length) return ''
  let rowStr = ''
  productData.techdata.forEach((item) => {
    let { label } = item
    if (item.unit) label += ` (${item.unit})`
    rowStr += `<tr><td width="20%">${label}</td><td width="20%">${item.value}</td></tr>`
  })
  return `<div class="bold">
    <h3>${tr(translations, 'technical_data', 'Technical Data').toLocaleUpperCase()}</h3>
    <table class="table small" style="font-weight: bold;">
      <tbody>
        ${rowStr}
      </tbody>
    </table>
  </div>`
}

const getProductInfo = (
  productData: ProductDetailItemType,
  translations: TranslationsPDFType,
): string => {
  const imagesArr: string[] = productData.images.map((item) => {
    const fullImage = item.urls.find((imageUrl) => imageUrl.type === 'full')?.url || ''
    return fullImage
  })

  const shortDesc = productData.texts.find((item) => item.type === 'short')?.value || ''
  const longDesc = productData.texts.find((item) => item.type === 'long')?.value || ''

  const technicalDataSec = `<div class="technical-data-sec">
    ${getTechData(productData, translations)}
  </div>`

  const orderNumber = `${tr(translations, 'order_number', 'Order number')}: ${
    productData.partnumberFormatted
  }`
  const eanNumber = `${tr(translations, 'ean', 'EAN')}: ${productData.ean}`
  const priceNet = `${tr(translations, 'net_priceNumber', 'Net')}`
  const priceGross = `${tr(translations, 'gross_priceNumber', 'Gross')}`
  const priceRemark = `${tr(translations, 'price_remark')}`

  let str = `<div class="medium section1" style="display: inline-flex; align-items: flex-start;">
    <img src="${imagesArr[0]}" alt="product-image" style="width: 45%;object-fit: contain; float: left;">
    <div>
      <h1 class="bold">${productData.name}</h1>
      <p class="bold medium" style="margin:0">${orderNumber}</p>
      <p class="bold medium" style="margin:2">${eanNumber}</p>`

  if (productData.priceNetFormatted) {
    str += `<p class="bold medium" style="margin:2">${priceNet} ${productData.priceNetFormatted}</p>`
  }

  if (productData.priceGrossFormatted) {
    str += `<p class="bold medium" style="margin:2">${priceGross} ${productData.priceGrossFormatted}</p>`
  }

  if (productData.priceNetFormatted && productData.priceGrossFormatted) {
    str += `<p class="medium" style="margin:2">${priceRemark}</p>`
  }

  str += `<p class="bold medium">${shortDesc ? `<p class="bold medium">${shortDesc}</p>` : ''}</p>
      ${longDesc ? `<p>${longDesc}</p>` : ''}
    </div>
  </div>
  ${technicalDataSec}`

  return str
}

export default getProductInfo
