import { AccessoryFilter, FilterableItems, Maybe } from 'services/types'
import { useEffect, useState } from 'react'
import { Col, Dropdown, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ProductGroupDataType } from 'types'
import { sortByStringPropertyAscending } from 'config/libs/helpers'
import { selectTranslations } from 'redux/selector'
import { useHeaderContext } from 'components/Page/headerContext'
import {
  filterCategoriesByParentId,
  getSelectedGroups,
  isCategoryDataExist,
} from './groupListDropdown.utils'

export type AdditionalFiltersType = {
  material: string
  standardNominalSize: string
}

type GroupListFilterProps = {
  productGroupId: number
  categories: ProductGroupDataType[]
  filterableItems: Maybe<FilterableItems>
  onFiltersUpdate: (
    selectedCategories?: ProductGroupDataType[],
    selectedAdditionalFilters?: AdditionalFiltersType,
  ) => void
}

const INIT_ADDITIONAL_FILTER = {
  material: '',
  standardNominalSize: '',
}

const GroupListFilter = ({
  productGroupId,
  categories,
  filterableItems,
  onFiltersUpdate,
}: GroupListFilterProps) => {
  const translations = useSelector(selectTranslations)
  const { headerHeight, ribbonHeight } = useHeaderContext()
  const [searchParams, setSearchParams] = useSearchParams()

  const categoryIds = searchParams.get('categoryIds')
  const additionalFilters = searchParams.get('additionalFilters')

  const sortedDropdownData = sortByStringPropertyAscending<ProductGroupDataType>(categories, 'name')
  const firstDropdownData = sortedDropdownData.filter((group) => group.parentId === productGroupId)

  const [categoriesFilter, setCategoriesFilter] = useState<ProductGroupDataType[][]>([
    firstDropdownData,
  ])
  const [selectedCategories, setSelectedCategories] = useState<ProductGroupDataType[]>([])
  const [selectedAdditionalFilters, setSelectedAdditionalFilters] =
    useState<AdditionalFiltersType>(INIT_ADDITIONAL_FILTER)
  const [isCollapsed, setIsCollapsed] = useState(false)

  const pleaseSelectTxt = translations.please_select || 'Please Select'
  const selectViaMachineTxt = translations.select_via_machine || 'Selection via machine'
  const additionalFiltersTxt = translations.additional_filters || 'Additional Filters'

  const handleCategoryFilter = (groupIds: number[], filters: AdditionalFiltersType) => {
    const categoryFilterArr = [firstDropdownData]

    groupIds.forEach((id) => {
      const listCategories = filterCategoriesByParentId(id, sortedDropdownData)

      if (isCategoryDataExist(categoryFilterArr, listCategories) || listCategories.length === 0)
        return

      categoryFilterArr.push(listCategories)
    })

    setCategoriesFilter(categoryFilterArr)

    const selectedCategories = getSelectedGroups(groupIds, sortedDropdownData)
    setSelectedCategories(selectedCategories)
    setSelectedAdditionalFilters(filters)

    onFiltersUpdate(selectedCategories, filters)
  }

  const onResetFilter = () => {
    setCategoriesFilter([firstDropdownData])
    setSelectedCategories([])
    setSelectedAdditionalFilters(INIT_ADDITIONAL_FILTER)
    onFiltersUpdate([], INIT_ADDITIONAL_FILTER)
  }

  useEffect(() => {
    const groupIds: number[] = JSON.parse(categoryIds ?? '[]')
    const filters: AdditionalFiltersType = JSON.parse(additionalFilters ?? '{}')

    /** Reset everything */
    if (groupIds.length === 0) {
      onResetFilter()
      return
    }

    handleCategoryFilter(groupIds, filters)
  }, [categoryIds, additionalFilters])

  const updateCategoryIds = (selectedGroupId: number, index: number) => {
    const currentCategoryIds = selectedCategories.map((category) => category.id)
    if (currentCategoryIds[index]) {
      currentCategoryIds[index] = selectedGroupId
      currentCategoryIds.splice(index + 1)
    } else {
      currentCategoryIds.push(selectedGroupId)
    }
    return currentCategoryIds
  }

  const onSelectCategory = (eventKey: string | null, index: number) => {
    if (!eventKey) return

    const selectedGroupItem = sortedDropdownData.find((group) => group.id === Number(eventKey))

    if (!selectedGroupItem) return

    const currentCategoryIds = updateCategoryIds(selectedGroupItem.id, index)
    setSelectedAdditionalFilters(INIT_ADDITIONAL_FILTER)
    setSearchParams({
      categoryIds: JSON.stringify(currentCategoryIds),
      additionalFilters: JSON.stringify(INIT_ADDITIONAL_FILTER),
    })
  }

  const onSelectAdditional = (eventKey: string | null, objectType: keyof AdditionalFiltersType) => {
    if (!eventKey) return

    const additionalFilter = { ...selectedAdditionalFilters, [objectType]: eventKey }
    setSelectedAdditionalFilters(additionalFilter)
    setSearchParams({
      categoryIds: JSON.stringify(selectedCategories.map((category) => category.id)),
      additionalFilters: JSON.stringify(additionalFilter),
    })
    onFiltersUpdate(selectedCategories, selectedAdditionalFilters)
  }

  const toggleViewVisibility = () => setIsCollapsed((prev) => !prev)

  const renderCategoryItem = (data: ProductGroupDataType[], index: number) => (
    <Dropdown
      key={data[0].id}
      className='group-list-dropdown mt-2'
      onSelect={(eventKey) => onSelectCategory(eventKey, index)}
    >
      <Dropdown.Toggle id={`group-list-dropdown-${index}`} className='w-100'>
        {selectedCategories[index]?.name ?? pleaseSelectTxt}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {data.map((group) => (
          <Dropdown.Item key={group.id} eventKey={group.id.toString()}>
            {group.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )

  const renderCategoriesDropdowns = () => {
    if (!categoriesFilter.length) return null

    return categoriesFilter.map((data, index) => renderCategoryItem(data, index))
  }

  const renderAdditionalItem = (
    data: Maybe<AccessoryFilter> | undefined,
    dropdownType: keyof AdditionalFiltersType,
  ) => {
    if (!data) return null

    const { name, filterableValues } = data

    return (
      <Dropdown
        key={name}
        className='group-list-dropdown mt-2'
        onSelect={(eventKey) => onSelectAdditional(eventKey, dropdownType)}
      >
        <Dropdown.Toggle className='w-100'>
          {selectedAdditionalFilters[dropdownType] !== ''
            ? selectedAdditionalFilters[dropdownType]
            : pleaseSelectTxt}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {filterableValues?.map((item) => (
            <Dropdown.Item key={item?.value} eventKey={item?.value?.toString()}>
              {item?.value}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  const renderAdditionalDropdowns = () => {
    const { material, standardNominalSize } = filterableItems ?? {}
    
    if (!material && !standardNominalSize) return null

    return (
      <div className='mt-4'>
        <h5 className='ak-bold'>{additionalFiltersTxt}</h5>
        {renderAdditionalItem(material, 'material')}
        {renderAdditionalItem(standardNominalSize, 'standardNominalSize')}
      </div>
    )
  }

  const renderToggleButton = (isCollapsed: boolean, toggleViewVisibility: () => void) => (
    <span className='cursor-pointer' role='button' onClick={toggleViewVisibility}>
      <FontAwesomeIcon icon={isCollapsed ? faAngleDown : faAngleUp} />
    </span>
  )

  return (
    <div className='dropdown-container' style={{ top: headerHeight + ribbonHeight }}>
      <Row>
        <Col md='11'>
          <h5 className='ak-bold'>{selectViaMachineTxt}</h5>
          {!isCollapsed && (
            <Row>
              {renderCategoriesDropdowns()}
              {renderAdditionalDropdowns()}
            </Row>
          )}
        </Col>
        <Col md='1' className='d-flex justify-content-end pt-2'>
          {renderToggleButton(isCollapsed, toggleViewVisibility)}
        </Col>
      </Row>
    </div>
  )
}

export default GroupListFilter
