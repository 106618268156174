import { getProductDetailDbById } from 'database/queries/product'
import { toast } from 'react-toastify'
import { getProductDetailQuery } from 'services'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { ProductDetailResponseType } from 'types'
import { PAGE_IDS, PAGE_NAMES, PAGE_TYPES, TRANSLATIONS } from 'config/constants'
import { selectProductComparison } from 'redux/comparison/selector'
import useAnalytics from 'hooks/useAnalytics'
import useDataFetcher from 'hooks/useDataFetcher'
import useNavigation from 'hooks/useNavigation'
import { useProductComparison } from 'features/Comparison/hooks/useProductComparison'
import { useGlobalModalContext } from 'features/Modal/GlobalModal'
import Page from 'components/Page/Page'
import ProductFinderUI from './ProductFinder.ui'
import { ZOOVU_EVENT_NAMES, getDefaultZoovuCode, getZoovuLocale, toZoovuUrl } from './utils'

const ProductFinder = (): React.ReactElement => {
  const location = useLocation()
  const { onDynamicNavigate } = useNavigation()
  const { setPageInfoAnalytics } = useAnalytics()
  const { productGroupCode } = useParams()
  const { showModal, hideModal } = useGlobalModalContext()
  const { fetchData } = useDataFetcher()
  const { addProductToComparison, deleteProductFromComparison } = useProductComparison()

  const [zoovuIframeUrl, setZoovuIframeUrl] = useState('')

  const productCompare = useSelector(selectProductComparison)

  const pageId = location.state?.pageId || PAGE_IDS.PRODUCT_FINDER

  const initAdvisorUrl = async () => {
    const productCompareIds = productCompare.join(',')
    const zoovuAdvisorCode = productGroupCode || getDefaultZoovuCode()

    const zoovuLocale = await getZoovuLocale()

    setZoovuIframeUrl(toZoovuUrl(zoovuAdvisorCode, zoovuLocale, productCompareIds))
  }

  useEffect(() => {
    initAdvisorUrl()
  }, [productGroupCode])

  useEffect(() => {
    setPageInfoAnalytics({
      pageName: PAGE_NAMES.PRODUCT_FINDER,
      pageType: PAGE_TYPES.PRODUCT_FINDER,
    })
  }, [])

  const handleSelectCategory = (categoryId?: string) => {
    if (!categoryId) {
      throw new Error('Category ID is required')
    }
    onDynamicNavigate(PAGE_IDS.PRODUCT_FINDER, { code: categoryId })
  }

  const handleSelectProductDetail = (productId?: string) => {
    if (!productId) return
    onDynamicNavigate(Number(productId))
  }

  const loadProductAndShowModal = async (id: string) => {
    showModal('LOADING_MODAL', undefined)
    try {
      const productDetailResponse = await fetchData<number, ProductDetailResponseType>(
        getProductDetailQuery,
        getProductDetailDbById,
        Number(id),
        Number(id),
      )
      await addProductToComparison(Number(id), JSON.parse(productDetailResponse?.data ?? '{}'))
    } catch (error) {
      throw new Error('Error adding product comparison')
    }
  }

  const addProductComparison = async (id?: string) => {
    if (!id) {
      throw new Error('Product ID is required')
    }
    await loadProductAndShowModal(id)
  }

  const removeProductComparison = async (id?: string) => {
    if (!id) {
      throw new Error('Product ID is required')
    }
    await deleteProductFromComparison(Number(id))
  }

  useEffect(() => {
    const eventFunc = async (event: { data: Record<string, string> }) => {
      try {
        switch (event.data?.eventName) {
          case ZOOVU_EVENT_NAMES.SELECT_CATEGORY:
            return handleSelectCategory(event.data?.categoryId)
          case ZOOVU_EVENT_NAMES.SELECT_PRODUCT:
            return handleSelectProductDetail(event.data?.productId)
          case ZOOVU_EVENT_NAMES.ADD_TO_COMPARE:
            return await addProductComparison(event.data?.productId)
          case ZOOVU_EVENT_NAMES.REMOVE_FROM_COMPARE:
            return await removeProductComparison(event.data?.productId)
        }
      } catch (error) {
        console.error('Error in zoovu event listener:', error)
        toast('Error in zoovu event')
      }
    }

    window.addEventListener('message', eventFunc)
    return () => {
      window.removeEventListener('message', eventFunc)
    }
  }, [])

  return (
    <Page
      pageId={pageId}
      className='product-finder-container p-0'
      breadCrumbTitle={TRANSLATIONS.productFinder}
    >
      <ProductFinderUI zoovuIframeUrl={zoovuIframeUrl} showOnlineGuide={!productGroupCode} />
    </Page>
  )
}

export default ProductFinder
