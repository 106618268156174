import { common, homeDb, professionalDb } from 'database/db'
import { GetProductListByRootlineResponse } from 'services/types'
import {
  ProductDetailResponseType,
  ProductGroupResponseType,
  ProductListResponseType,
  SubProductGroupItemType,
  SubProductGroupResponseType,
} from 'types'

export const getProductGroupDbById = async (
  productGroupId: number,
): Promise<ProductGroupResponseType | undefined> => {
  try {
    const result = await common.dcaProductGroup.where({ productgroupId: productGroupId }).first()
    return result
  } catch (error) {
    console.error('Error getProductGroupById', error)
    throw error
  }
}

export const getSubProductGroupDbByIds = async (
  productGroupIds: number[],
): Promise<SubProductGroupResponseType | undefined> => {
  try {
    // Use the toArray method to get all matching sub product groups
    const response = await common.dcaSubProductGroup
      .where('parentId')
      .anyOf(productGroupIds)
      .toArray()

    // If no matching sub product groups are found, return undefined
    if (response.length === 0) return undefined

    /**
     * Handle data to make it have same structure as API response
     */
    const listSubProductGroups: SubProductGroupItemType[] = response
      .map((sub) => sub.data)
      .reduce((prev, current) => [...prev, ...current], [])

    // Create a new object with the required fields and the merged listSubProductGroups array
    const result: SubProductGroupResponseType = {
      export: response[0].export,
      country: response[0].country,
      language: response[0].language,
      createdAt: response[0].createdAt,
      year: Number(response[0].year),
      data: listSubProductGroups,
    }

    return result
  } catch (error) {
    console.error('Error getSubProductGroupDbByIds', error)
    throw error
  }
}

export const getProductListDbById = async (
  productgroupId: number,
): Promise<ProductListResponseType | undefined> => {
  try {
    const result = await common.dcaProductList.where({ productgroupId }).first()

    if (!result) return

    /**
     * Handle data to make it have same structure as API response
     */
    return {
      export: result.export,
      country: result.country,
      language: result.language,
      createdAt: result.createdAt,
      year: Number(result.year),
      data: JSON.parse(result.data),
      productGroupData: result.productGroupData,
    }
  } catch (error) {
    console.error('Error getProductListDbById', error)
    throw error
  }
}

export const getProductListDbByRootLine = async (
  productListIds: number[],
): Promise<GetProductListByRootlineResponse | undefined> => {
  try {
    const productsHome = await homeDb.dcaProductDetails
      .filter((product) =>
        product.data.rootline.some((rootline) => productListIds.includes(rootline.id)),
      )

      .toArray()

    const productsProfessional = await professionalDb.dcaProductDetails
      .filter((product) =>
        product.data.rootline.some((rootline) => productListIds.includes(rootline.id)),
      )
      .toArray()

    const products = [...productsHome, ...productsProfessional]

    if (!products || products.length === 0) return

    /**
     * Handle data to make it have same structure as API response
     */
    const productMapping = products.map((product) => {
      return {
        businessUnit: product.businessUnit,
        id: product.data.id,
        images: product.data.images,
        name: product.data.name,
        productType: product.data.productType,
        texts: product.data.texts,
        priceNetFormatted: product.data.priceNetFormatted,
        priceGrossFormatted: product.data.priceGrossFormatted,
      }
    })

    return {
      country: products[0].country,
      createdAt: products[0].createdAt,
      data: productMapping,
      export: products[0].export,
      language: products[0].language,
      year: Number(products[0].year),
      productGroupData: '',
    }
  } catch (error) {
    console.error('Error getProductDetailsDBByRootLine', error)
    throw error
  }
}

export const getProductDetailDbById = async (
  productId: number,
): Promise<ProductDetailResponseType | undefined> => {
  try {
    const productsHome = await homeDb.dcaProductDetails.where({ productId }).first()
    const productsProfessional = await professionalDb.dcaProductDetails.where({ productId }).first()

    const product = productsHome ?? productsProfessional

    if (!product) return

    /**
     * Handle data to make it have same structure as API response
     */
    const result = {
      export: product.export,
      year: product.year,
      country: product.country,
      createdAt: product.createdAt,
      data: JSON.stringify(product.data),
      language: product.language,
    }

    return result
  } catch (error) {
    console.error('Error getProductDetailDbById', error)
    throw error
  }
}
