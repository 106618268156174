import { Maybe, Product } from 'services/types'
import { ProductGroupDataType } from 'types'
import { AdditionalFiltersType } from './GroupListFilter'

export const getSelectedGroups = (
  groupIds: number[],
  sortedDropdownData: ProductGroupDataType[],
): ProductGroupDataType[] => {
  if (groupIds.length === 0) return []

  return groupIds
    .map((id) => sortedDropdownData.find((group) => group.id === id))
    .filter((group): group is ProductGroupDataType => !!group)
}

export const filterCategoriesByParentId = (
  parentId: number,
  sortedDropdownData: ProductGroupDataType[],
) => {
  return sortedDropdownData.filter((group) => group.parentId === parentId)
}

export const isCategoryDataExist = (
  categoryFilterArr: ProductGroupDataType[][],
  listCategories: ProductGroupDataType[],
) => {
  return categoryFilterArr.some((data) =>
    data.some((group) => listCategories.some((category) => category.id === group.id)),
  )
}

export const filterAdditionalProduct = (
  data: Maybe<Product>[],
  filterData?: AdditionalFiltersType,
) => {
  if (!filterData) return data

  const { material, standardNominalSize } = filterData
  return data?.filter(
    (product) =>
      (material === '' || product?.techdata?.some((techData) => techData?.value === material)) &&
      (standardNominalSize === '' ||
        product?.techdata?.some((techData) => techData?.value === standardNominalSize)),
  )
}
