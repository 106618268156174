import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { PageContentResponseType, ProductDetailItemType } from 'types'
import { COMMON, PRODUCT_DETAILS_SECTIONS } from 'config/constants'
import { useSelector } from 'redux/hooks/useSelector'
import { RootState } from 'redux/reducers'
import { updateProductDetailFilterState } from 'redux/reducers/navigationReducer'
import { selectStructure, selectTranslations } from 'redux/selector'
import { selectProductDetailFilterState } from 'redux/selector/navigationSelector'
import { useAppContext } from 'context/AppContext'
import useNavigation from 'hooks/useNavigation'
import ContentAnchor from 'features/ManualContent/components/ContentAnchor'
import { useGlobalModalContext } from 'features/Modal/GlobalModal'
import { createProductDetailPDF } from 'features/NewPDF/ProductDetailPDF/ProductDetailPDF'
import Accessories, { FilterStateType } from 'features/Product/components/Accessories/Accessories'
import Applications from 'features/Product/components/Applications'
import CompatibleMachines from 'features/Product/components/CompatibleMachines'
import ConfigurableComponents from 'features/Product/components/ConfigurableComponents'
import Description from 'features/Product/components/Description/Description'
import DetergentWarnings from 'features/Product/components/DetergentWarning'
import Documents from 'features/Product/components/Documents'
import EquipmentAndIcons from 'features/Product/components/EquipmentAndIcons'
import FeaturesAndBenefit from 'features/Product/components/FeaturesAndBenefit'
import Ribbon from 'features/Product/components/Ribbon'
import Videos from 'features/Product/components/Videos'
import useAnchorLinks from 'features/Product/hooks/useAnchorLinks'
import { ProductsNavType } from './ProductDetail'
import 'styles/pages/productDetail.scss'

type UIProps = {
  data: PageContentResponseType
  productDetails: ProductDetailItemType
  productsNav: ProductsNavType
}

const ProductDetailUI = (props: UIProps): React.ReactElement => {
  const { data, productDetails, productsNav } = props
  const { pageId } = data
  const { name } = productDetails
  const description = productDetails?.texts.find((textData) => textData.type === 'short')

  const dispatch = useDispatch()
  const { onDynamicNavigate } = useNavigation()
  const { getAnchorDataFilter } = useAnchorLinks()
  const { showModal, hideModal } = useGlobalModalContext()
  const [filterState, setFilterState] = useState<FilterStateType>({})

  const productDetailNavState = useSelector((state: RootState) =>
    selectProductDetailFilterState(state, pageId),
  )
  const structure = useSelector(selectStructure)
  const translations = useSelector(selectTranslations)

  const {
    setState,
    state: { tourActive },
  } = useAppContext()

  useEffect(() => {
    setTimeout(() => {
      if (tourActive) {
        setState({ run: true, stepIndex: 6 })
      }
    }, 300)
  }, [])

  // handle update filter data in accessories
  useEffect(() => {
    if (productDetailNavState && Object.keys(productDetailNavState).length > 0) {
      setFilterState(productDetailNavState)
    }
  }, [productDetailNavState])

  const anchorsData = getAnchorDataFilter(productDetails)
  const productSectionRef = useRef<HTMLDivElement>(null)

  const isPastProduct = productDetails?.productType === COMMON.PAST_PRODUCT

  const onExportPDF = (onDone: () => void) => {
    const ribbonData = { headline: productDetails.name, description: description?.value ?? '' }
    createProductDetailPDF(
      {
        pageContent: data,
        productData: productDetails,
        ribbonData,
        structure: structure,
        translations: translations,
      },
      onDone,
    )
  }

  const onUpdateFilterValue = (obj: FilterStateType) => {
    setFilterState((state) => ({ ...state, ...obj }))
  }

  const renderRibbon = () => {
    if (!name && !description) return null

    const onAddFavorite = () => {
      showModal('FAVORITES_MODAL', {
        showModal: true,
        itemName: name,
        pageContent: data,
        handleClose: hideModal,
      })
    }

    return (
      <Ribbon
        pageId={pageId}
        headline={name}
        description={description?.value ?? ''}
        productsNav={productsNav}
        onAddFavorite={onAddFavorite}
      />
    )
  }

  const renderAnchor = () => {
    return <ContentAnchor anchorsData={anchorsData} anchorRef={productSectionRef} />
  }

  const onGoNextPage = (nextPageId: number) => {
    dispatch(updateProductDetailFilterState({ pageId, filterState }))
    return onDynamicNavigate(nextPageId)
  }

  const renderProductItem = (targetSection: string) => {
    switch (targetSection) {
      case PRODUCT_DETAILS_SECTIONS.DESCRIPTION:
        if (isPastProduct) return null
        return <Description productDetails={productDetails} onExportPDF={onExportPDF} />

      case PRODUCT_DETAILS_SECTIONS.EQUIPMENT:
        if (isPastProduct) return null
        return (
          <EquipmentAndIcons equipment={productDetails.equipment} icons={productDetails.icons} />
        )

      case PRODUCT_DETAILS_SECTIONS.FEATURE_BENEFITS:
        if (isPastProduct) return null

        return <FeaturesAndBenefit featureBenefits={productDetails.featureBenefits} />

      case PRODUCT_DETAILS_SECTIONS.DOCUMENTS:
        return <Documents documents={productDetails.documents} />

      case PRODUCT_DETAILS_SECTIONS.ACCESSORIES:
        return (
          <Accessories
            accessories={productDetails.accessories}
            accessoryCategories={productDetails.acc_categories}
            onUpdateFilterValue={onUpdateFilterValue}
            onGoNextPage={onGoNextPage}
            filterValue={filterState}
          />
        )

      case PRODUCT_DETAILS_SECTIONS.DETERGENTS:
        return (
          <Accessories
            accessories={productDetails.detergents}
            detergentCategories={productDetails.det_categories}
            onUpdateFilterValue={onUpdateFilterValue}
            onGoNextPage={onGoNextPage}
            filterValue={filterState}
          />
        )

      case PRODUCT_DETAILS_SECTIONS.VIDEOS:
        return <Videos videos={productDetails.videos} />

      case PRODUCT_DETAILS_SECTIONS.DETERGENT_WARNINGS:
        if (isPastProduct) return null
        return <DetergentWarnings detergentWarnings={productDetails.detergentWarnings} />

      case PRODUCT_DETAILS_SECTIONS.APPLICATIONS:
        if (isPastProduct) return null
        return <Applications applications={productDetails.applications} />

      case PRODUCT_DETAILS_SECTIONS.COMPATIBLE_MACHINES:
        if (isPastProduct) return null
        return <CompatibleMachines compatibleProducts={productDetails.compatibleProducts} />

      case PRODUCT_DETAILS_SECTIONS.CONFIGURABLE_COMPONENT:
        if (isPastProduct) return null
        return (
          <ConfigurableComponents configurableComponents={productDetails.configurableComponents} />
        )

      default:
        break
    }
  }

  const renderProductSection = () => {
    return (
      <div>
        <div ref={productSectionRef}>
          {anchorsData.map((item) => (
            <React.Fragment key={item.targetId}>{renderProductItem(item.targetId)}</React.Fragment>
          ))}
        </div>
      </div>
    )
  }

  return (
    <div className='pb-3'>
      {renderRibbon()}

      {renderAnchor()}

      {renderProductSection()}
    </div>
  )
}

export default ProductDetailUI
