/* eslint-disable camelcase */
import { Auth } from 'aws-amplify'

const getUserAccessToken = async () => {
  try {
    const session = await Auth.currentSession()
    const accessToken = session.getAccessToken().getJwtToken()
    return accessToken
  } catch (error) {
    console.error('Error getting access token:', error)
    return null
  }
}
 

export { getUserAccessToken }