import { Button, Card, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { PAGE_IDS } from 'config/constants'
import { selectTranslations } from 'redux/selector'
import useNavigation from 'hooks/useNavigation'

const PolicyBlock = () => {
  const { onDynamicNavigate } = useNavigation()

  const translations = useSelector(selectTranslations)

  const privacyStatementTxt = translations.privacy_statement ?? 'Privacy Statement'
  const cookiePolicyTxt = translations.cookie_policy ?? 'Cookie Policy'

  const onGoPrivacyPolicy = () => {
    onDynamicNavigate(PAGE_IDS.PRIVACY_POLICY)
  }

  const onGoCookiePolicy = () => {
    onDynamicNavigate(PAGE_IDS.COOKIE_POLICY)
  }

  return (
    <>
      <Card className='policy-block setting-card'>
        <Row>
          <h6 className='settings-heading'>
            <i className='karcher-icon settings-icons'>&#xe918;</i>
            {privacyStatementTxt}
          </h6>
        </Row>

        <div className='mt-4'>
          <Button className='app-button' onClick={onGoPrivacyPolicy}>
            {privacyStatementTxt.toUpperCase()}
          </Button>
        </div>

        <div className='mt-4'>
          <Button className='app-button' onClick={onGoCookiePolicy}>
            {cookiePolicyTxt.toUpperCase()}
          </Button>
        </div>
      </Card>
    </>
  )
}

export default PolicyBlock
