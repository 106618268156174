import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Slide, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Container } from 'react-bootstrap'
import { useSelector } from 'react-redux'
// WHY? this is in fact not used here, but this import makes sure we have the
// window.digitalData object present in EVERY page. We need this for adobe analytics
import digitalData from 'config/adobeAnalytics/digitalData'
// Do not remove this line
import { Sentry } from 'config/libs/sentry'
import { AppRoutes } from 'config/router/Routes'
import { getAppLang } from 'config/utils/CommonFunction'
import { useInitDownloadManager } from 'redux/download/download.hook'
import { selectCurrentLanguage } from 'redux/selector'
import { AppProvider } from 'context/AppContext'
import useAppDataInit from 'hooks/useAppDataInit'
import LoadingPage from 'features/Common/screens/LoadingPage'
import MigrationDB from 'features/Common/screens/MigrationOldDB/MigrationDB'
import MarkingTooltip from 'features/Marking/components/MarkingTooltip'
import { GlobalModalProvider } from 'features/Modal/GlobalModal'
import OnboardingTour from 'features/Onboarding/screens/OnboardingTour'
import './App.scss'

window.digitalData = digitalData

const App = () => {
  const { loading } = useAppDataInit()
  useInitDownloadManager()
  const currentLanguage = useSelector(selectCurrentLanguage)
  const appLang = getAppLang(currentLanguage?.languageCode || '')

  if (loading) {
    return <LoadingPage />
  }

  return (
    <Container id='App' fluid className='app-container p-0' {...appLang}>
      <AppProvider>
        <GlobalModalProvider>
          <DndProvider backend={HTML5Backend}>
            <AppRoutes />
            <OnboardingTour {...appLang }/>
            <MarkingTooltip />
            <ToastContainer transition={Slide} hideProgressBar />
          </DndProvider>
        </GlobalModalProvider>
      </AppProvider>
    </Container>
  )
}

const DBMigrationApp = () => {
  return (
    <MigrationDB>
      <App />
    </MigrationDB>
  )
}

export default Sentry.withProfiler(DBMigrationApp)
