import { Container, Row, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { ProductDetailItemType, TextItemType } from 'types'
import { TRANSLATIONS } from 'config/constants'
import {
  getEquipmentTable,
  getShortDescriptionTable,
  getTechDataTable,
} from 'config/utils/ComparisonFunction'
import { selectTranslations } from 'redux/selector'
import { useGlobalModalContext } from 'features/Modal/GlobalModal'
import { createProductComparisonPDF } from 'features/NewPDF/ProductComparison/ProductComparisonPDF'
import { usePDFLoading } from 'features/NewPDF/hooks/usePDFLoading'
import DescriptionCompare from '../components/DescriptionCompare'
import EquipmentCompare from '../components/EquipmentCompare'
import ImagesSection from '../components/ImagesSection'
import TechDataCompare from '../components/TechDataCompare'

type ComparisonUIProps = {
  productCompareDetails: ProductDetailItemType[]
}

const ComparisonUI = ({ productCompareDetails }: ComparisonUIProps) => {
  const translations = useSelector(selectTranslations)
  const { showModal, hideModal } = useGlobalModalContext()

  const productComparison = translations.product_comparison ?? TRANSLATIONS.productComparison
  const exportTxt = translations.export ?? TRANSLATIONS.export
  const noProductTxt =
    translations.no_product_is_added_for_comparison_alert ??
    TRANSLATIONS.noProductAreSelectedForComparison
  const priceExist = productCompareDetails.find((item) => item.priceNetFormatted)
  const priceRemarkTitle =
    translations.price_remark ?? '*recommended retail price. Prices by the dealer may vary.'

  const compareListTechdata = productCompareDetails.map((item) => item.techdata)
  const equipmentsCompareData = productCompareDetails.map((item) => item.equipment)
  const descriptionsData: TextItemType[] = productCompareDetails
    .map((item) => item.texts.find((text) => text.type === 'short'))
    .filter((text): text is TextItemType => Boolean(text))
  const techDataTable = getTechDataTable(compareListTechdata)
  const equipmentsTable = getEquipmentTable(equipmentsCompareData)
  const descriptionTable = getShortDescriptionTable(descriptionsData)

  const onExportProductCompare = (onDone: () => void) => {
    createProductComparisonPDF(
      {
        products: productCompareDetails,
        techLabels: techDataTable.map((item) => item.map((i) => i.value)),
        equipmentLabels: equipmentsTable.map((item) => item.map((i) => i.value)),
      },
      translations,
      onDone,
    )
  }

  const { pdfLoading, handleExportPDF } = usePDFLoading(onExportProductCompare)

  const onAddFavorite = () => {
    const compareIds = productCompareDetails.map((item) => ({
      id: item.id,
    }))
    showModal('FAVORITES_MODAL', {
      showModal: true,
      itemName: productComparison,
      metadata: JSON.stringify(compareIds),
      handleClose: hideModal,
    })
  }

  const renderHeader = () => {
    if (productCompareDetails.length === 0) {
      return (
        <Row>
          <h1 className='ak-bold mt-3'>{productComparison.toUpperCase()}</h1>
          <h5 className='text-center m-5'>{noProductTxt}</h5>
        </Row>
      )
    }
    return (
      <Row className='d-flex align-items-center'>
        <div className='d-flex align-items-center'>
          <h1 className='ak-bold mt-3'>{productComparison.toUpperCase()}</h1>

          <span className='px-4'>
            <i className='karcher-icon cursor-pointer' onClick={onAddFavorite}>
              &#xe843;
            </i>
          </span>
          {pdfLoading ? (
            <div className='d-flex align-items-center px-3'>
              <Spinner style={{ width: 12, height: 12 }} />
            </div>
          ) : (
            <div
              className='d-flex align-items-center px-3 cursor-pointer'
              onClick={handleExportPDF}
            >
              <i className='karcher-icon'>&#xe914;</i>
              <span className='export-text'>{exportTxt}</span>
            </div>
          )}
        </div>
      </Row>
    )
  }

  return (
    <Container>
      {renderHeader()}

      <ImagesSection productCompareDetails={productCompareDetails} />

      <TechDataCompare techDataTable={techDataTable} />

      <EquipmentCompare equipmentsTable={equipmentsTable} />

      <DescriptionCompare descriptionTable={descriptionTable} />

      {priceExist && priceRemarkTitle}
    </Container>
  )
}

export default ComparisonUI
