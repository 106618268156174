import { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { PAGE_IDS, PAGE_NAMES, PAGE_TYPES } from 'config/constants'
import { isDesktop } from 'config/utils/CommonFunction'
import { selectTranslations } from 'redux/selector'
import { useAppContext } from 'context/AppContext'
import useAnalytics from 'hooks/useAnalytics'
import Breadcrumb from 'components/Breadcrumb/Breadcrumb'
import Header from 'components/Header/Header'
import AppVersionBlock from '../components/AppVersionBlock'
import ChangeCountryBlock from '../components/ChangeCountryBlock'
import DownloadBlockDesktop from '../components/DownloadBlock.desktop'
import DownloadBlockWeb from '../components/DownloadBlock.web'
import ImprintBlock from '../components/ImprintBlock'
import OnboardingBlock from '../components/OnboardingBlock'
import PolicyBlock from '../components/PrivacyBlock'
import 'styles/pages/settings.scss'

const Settings = () => {
  const { setPageInfoAnalytics } = useAnalytics()
  const {
    setState,
    state: { tourActive },
  } = useAppContext()

  const translations = useSelector(selectTranslations)

  const settingsTitle = translations.settings ?? 'Settings'

  useEffect(() => {
    /** Analytics */
    setPageInfoAnalytics({
      pageName: PAGE_NAMES.SETTINGS,
      pageType: PAGE_TYPES.SETTINGS,
    })

    /** Onboarding tour */
    if (tourActive) {
      setTimeout(() => {
        setState({ run: true, stepIndex: isDesktop() ? 8 : 9 })
      }, 300)
    }
  }, [])

  return (
    <Container className='settings-screen px-0' fluid>
      <Header pageId={PAGE_IDS.SETTINGS} />

      <Breadcrumb pageId={PAGE_IDS.SETTINGS} staticTitle={settingsTitle} />

      <div className='settings-container'>
        <Container className='settings-content'>
          <Row>
            <Col md='3'>
              <h1>{settingsTitle}</h1>
            </Col>
          </Row>

          <Row className='mt-3'>
            <Col lg='4' md='4' sm='6' xs='12'>
              {isDesktop() ? <DownloadBlockDesktop /> : <DownloadBlockWeb />}
            </Col>

            <Col lg='4' md='4' sm='6' xs='12'>
              <OnboardingBlock />
            </Col>

            <Col lg='4' md='4' sm='6' xs='12'>
              <AppVersionBlock />
            </Col>
          </Row>

          <Row className='mt-4'>
            <Col lg='4' md='4' sm='6' xs='12'>
              <ChangeCountryBlock />
            </Col>
            <Col lg='4' md='4' sm='6' xs='12'>
              <ImprintBlock />
            </Col>
            <Col lg='4' md='4' sm='6' xs='12'>
              <PolicyBlock />
            </Col>
          </Row>
        </Container>
      </div>
    </Container>
  )
}

export default Settings
