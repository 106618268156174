import { truncate } from 'lodash'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { StructureItemType } from 'types'
import { categoryAnalytics, productDetailsCategoryAnalytics } from 'config/adobeAnalytics/analytics'
import { capitalizeFirstLetterOfEachWord } from 'config/libs/helpers'
import { selectTranslations } from 'redux/selector'
import 'styles/components/breadcrumb.scss'
import { selectProductComparison } from 'redux/comparison/selector'

type UIProps = {
  navigationArr: StructureItemType[]
  activeRoute?: StructureItemType
  staticTitle?: string
  onGoBack: () => void
  onGoToPage: (data: StructureItemType) => void
}

const BreadcrumbUI = (props: UIProps): React.ReactElement => {
  const { navigationArr, activeRoute, staticTitle, onGoBack, onGoToPage } = props

  const translations = useSelector(selectTranslations)
  const productCompare = useSelector(selectProductComparison)

  const backLabel = translations?.back || 'Back'

  const renderStaticBreadcrumbs = () => {
    return (
      <span className='catalogue-container'>
        <span className='ak-bold breadcrumb-link'>
          {capitalizeFirstLetterOfEachWord(staticTitle)}
        </span>
      </span>
    )
  }

  const getBreadcrumbTitle = (route: StructureItemType[]) => route[route.length - 1].title

  categoryAnalytics({
    primaryCategory: navigationArr[1]?.title ?? '',
    subCategory1: navigationArr[2]?.title ?? '',
    subCategory2: navigationArr[3]?.title ?? '',
    subCategory3: navigationArr[0]?.title ?? '',
  })
  if (navigationArr[navigationArr.length - 1]?.type === 'product') {
    const lengthArray = navigationArr.length - 1
    productDetailsCategoryAnalytics({
      primaryCategory: navigationArr[lengthArray]?.title ?? '',
      subCategory1: navigationArr[lengthArray - 1]?.title ?? '',
      subCategory2: navigationArr[lengthArray - 2]?.title ?? '',
      subCategory3: navigationArr[lengthArray - 3]?.title ?? '',
      subCategory4: navigationArr[lengthArray - 4]?.title ?? '',
      subCategory5: navigationArr[lengthArray - 5]?.title ?? '',
    })
  }

  // Render the breadcrumb links
  const renderBreadcrumbs = () => {
    if (staticTitle) return renderStaticBreadcrumbs()

    if (!navigationArr.length) {
      return null
    }

    return (
      <span className='catalogue-container'>
        {navigationArr.length <= 2 && (
          <span className='ak-bold'>
            {truncate(getBreadcrumbTitle(navigationArr), { length: 100 })}
          </span>
        )}

        {navigationArr.length > 2 &&
          navigationArr.map((route, index) => {
            if (index === 0 || index === 1) {
              return null
            }

            const isActive = route.id === activeRoute?.id

            return (
              <span
                key={`breadcrumb-link-${route.id}`}
                className={`breadcrumb-link ${isActive ? 'ak-bold' : ''}`}
                tabIndex={0}
                role='button'
                onClick={() => onGoToPage(route)}
              >
                {route.title} {index < navigationArr.length - 1 && '>'}{' '}
              </span>
            )
          })}
      </span>
    )
  }

  const renderRightNav = () => {
    return (
      <div style={{ display: 'flex', gap: 16 }}>
        <Link id='onboarding-icon-fav' className='right-nav-link' to='/favorites'>
          <i className='karcher-icon pr-3'>&#xe844;</i>
        </Link>
        <div style={{ position: 'relative' }}>
          {productCompare.length > 0 && (
            <div className='compare-count'>{productCompare.length}</div>
          )}
          <Link id='onboarding-icon-comparison' className='right-nav-link' to='/comparison'>
            <i className='karcher-icon'>&#xe917;</i>
          </Link>
        </div>
      </div>
    )
  }

  return (
    <Container className='breadcrumb-wrapper py-3' fluid>
      <Container className='breadcrumb-container' id='breadcrumb'>
        <Row>
          <Col md='8' className='d-flex h-100'>
            <span>{renderBreadcrumbs()}</span>
            <span tabIndex={0} role='button' onClick={onGoBack} className='go-back-btn'>
              <FontAwesomeIcon size='xs' icon={faChevronCircleLeft} />
              <span>{backLabel}</span>
            </span>
          </Col>

          <Col md={{ span: 1, offset: 3 }} className='d-flex h-100 justify-content-end'>
            {renderRightNav()}
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default BreadcrumbUI
