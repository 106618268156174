import { Maybe, Product } from 'services/types'
import { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { selectTranslations } from 'redux/selector'
import LoadingPage from 'features/Common/screens/LoadingPage'
import ProductItem from 'features/Product/components/ProductItem'

type ProductListProps = {
  isLoading: boolean
  productList: Maybe<Product>[]
  onAddProductToFavorite: (product: { id: number; name: string }) => Promise<void>
  onGoNextPage: (id: number) => void
}

const NUMBER_PRODUCTS_INIT = 4
const NUMBER_PRODUCTS_LOAD_MORE = 4

const DropdownProducts = ({
  isLoading,
  productList,
  onAddProductToFavorite,
  onGoNextPage,
}: ProductListProps) => {
  const translations = useSelector(selectTranslations)

  const [shownProducts, setShownProducts] = useState(productList.slice(0, NUMBER_PRODUCTS_INIT))

  const priceExist = productList?.some((product) => product?.priceNetFormatted !== null)
  const priceRemarkTitle =
    translations.price_remark ?? '*recommended retail price. Prices by the dealer may vary.'
  const noProductTxt = translations?.no_product || 'No product found'
  const showMoreTxt = translations.show_more ?? 'Show More'
  const showAllTxt = translations?.show_all ?? 'Show all'

  useEffect(() => {
    setShownProducts(productList.slice(0, NUMBER_PRODUCTS_INIT))
  }, [productList])

  const onShowMore = () => {
    const lastIndex = shownProducts.length - 1
    const nextIndex = productList.findIndex((p) => p?.id === shownProducts[lastIndex]?.id) + 1

    setShownProducts(
      shownProducts.concat(productList.slice(nextIndex, nextIndex + NUMBER_PRODUCTS_LOAD_MORE)),
    )
  }

  const onShowAll = () => {
    setShownProducts(productList)
  }

  const renderProductItem = (product: Maybe<Product>) => {
    if (!product) return null

    return (
      <Col md='3' key={product?.id}>
        <ProductItem
          product={product}
          key={product.id}
          onClickFavorite={() =>
            onAddProductToFavorite({ id: Number(product.id), name: product?.name ?? '' })
          }
          onClickProduct={() => onGoNextPage(Number(product.id))}
        />
      </Col>
    )
  }

  const renderProductList = () => {
    if (isLoading) return <LoadingPage />

    if (!shownProducts) return null

    if (shownProducts.length === 0) return <h5>{noProductTxt}</h5>

    return <Row>{shownProducts.filter((product) => !!product).map(renderProductItem)}</Row>
  }

  const renderButtonLoadMore = () => {
    if (shownProducts.length >= productList.length) return null

    return (
      <div>
        <Button variant='primary' className='btn-load-more' onClick={onShowMore}>
          {showMoreTxt} (
          {productList.length - shownProducts.length < NUMBER_PRODUCTS_LOAD_MORE
            ? productList.length - shownProducts.length
            : NUMBER_PRODUCTS_LOAD_MORE}
          )
        </Button>

        <Button variant='primary' className='btn-load-more ms-2' onClick={onShowAll}>
          {showAllTxt} ({productList.length - shownProducts.length})
        </Button>
      </div>
    )
  }

  return (
    <div className='mt-4'>
      {renderProductList()}

      {renderButtonLoadMore()}

      {priceExist && priceRemarkTitle}
    </div>
  )
}

export default DropdownProducts
