import { usePageContent } from 'redux/hooks/pageContent'
import { useProductList } from 'redux/hooks/productList'
import DividerPDF from 'features/PDF/components/DividerPDF'
import HeaderPDF from 'features/PDF/components/HeaderPDF'
import ProductListUI from 'features/Product/screens/ProductList/ProductList.ui'

type ProductListPDFProps = {
  pageId: number
  onDownloadStatus: (status: boolean) => void
}

const ProductListPDF = ({ pageId, onDownloadStatus }: ProductListPDFProps) => {
  const { productList, productGroup, loading, error: errorProduct } = useProductList(pageId)
  const { data, loading: pageLoading, error: errorPageContent } = usePageContent(pageId)

  const hasDataLoaded = !!productList && !!data && !!productGroup && !loading && !pageLoading

  if (hasDataLoaded) {
    onDownloadStatus(true)
  }

  if (errorProduct || errorPageContent) {
    onDownloadStatus(false)
  }

  if (hasDataLoaded) {
    return (
      <div className='product-list-container'>
        <HeaderPDF />
        <DividerPDF />
        <ProductListUI
          data={data}
          productList={productList}
          productGroup={productGroup}
          onGoNextPage={() => {}}
        />
      </div>
    )
  }

  return null
}

export default ProductListPDF
