import {
  ProductDetailResponseType,
  ProductGroupResponseType,
  ProductListResponseType,
  SubProductGroupResponseType,
} from 'types'
import { API_YEAR } from 'config/constants'
import { getGraphQLClient } from 'config/libs/globals'
import { getCurrentCountry, getCurrentLanguage } from 'config/utils/CommonFunction'
import {
  GET_PRODUCT_BY_GROUP_ID,
  GET_PRODUCT_BY_ID,
  GET_PRODUCT_LIST_BY_ID,
  GET_SUB_PRODUCT_GROUP_BY_ID,
} from './queries/productQueries'

const getProductGroupQuery = async (
  productGroupId: number,
): Promise<ProductGroupResponseType | undefined> => {
  try {
    const currentCountry = await getCurrentCountry()
    const currentLanguage = await getCurrentLanguage()
    const { data } = await getGraphQLClient().query({
      query: GET_PRODUCT_BY_GROUP_ID(
        productGroupId,
        currentLanguage.languageCode,
        currentCountry.code,
        API_YEAR,
      ),
    })
    if (!data) {
      throw new Error('data is undefined')
    }
    return data?.getProductGroupById
  } catch (error) {
    console.error('Error fetching product group by id:', error)
    throw new Error('Failed to fetch product group by id')
  }
}

const getSubProductGroupQuery = async (
  productGroupIds: number[],
): Promise<SubProductGroupResponseType> => {
  try {
    const currentCountry = await getCurrentCountry()
    const currentLanguage = await getCurrentLanguage()
    const { data } = await getGraphQLClient().query({
      query: GET_SUB_PRODUCT_GROUP_BY_ID(
        productGroupIds,
        currentLanguage.languageCode,
        currentCountry.code,
        API_YEAR,
      ),
    })
    if (!data) {
      throw new Error('data is undefined')
    }
    return data?.getSubProductGroupByIds
  } catch (error) {
    console.error('Error fetching sub product group by id:', error)
    throw new Error('Failed to fetch sub product group by id')
  }
}

const getProductListQuery = async (productGroupId: number): Promise<ProductListResponseType> => {
  try {
    const currentCountry = await getCurrentCountry()
    const currentLanguage = await getCurrentLanguage()

    const { data } = await getGraphQLClient().query({
      query: GET_PRODUCT_LIST_BY_ID(
        productGroupId,
        currentLanguage.languageCode,
        currentCountry.code,
        API_YEAR,
      ),
    })
    if (!data) {
      throw new Error('data is undefined')
    }
    return data?.getProductListById
  } catch (error) {
    console.error('Error getProductListQuery:', error)
    throw new Error('Failed getProductListQuery')
  }
}

const getProductDetailQuery = async (productId: number): Promise<ProductDetailResponseType> => {
  try {
    const currentCountry = await getCurrentCountry()
    const currentLanguage = await getCurrentLanguage()

    const { data } = await getGraphQLClient().query({
      query: GET_PRODUCT_BY_ID(
        productId,
        currentLanguage.languageCode,
        currentCountry.code,
        API_YEAR,
      ),
    })
    if (!data) {
      throw new Error('data is undefined')
    }
    return data?.getProductById
  } catch (error) {
    console.error('Error getProductDetailQuery:', error)
    throw new Error('Failed getProductDetailQuery')
  }
}

export { getProductGroupQuery, getSubProductGroupQuery, getProductListQuery, getProductDetailQuery }
