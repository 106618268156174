import { FormCheck } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { selectProductComparison } from 'redux/comparison/selector'
import { selectTranslations } from 'redux/selector'
import {
  ProductComparisonData,
  useProductComparison,
} from 'features/Comparison/hooks/useProductComparison'

type ComparisonButtonProps = {
  data: ProductComparisonData
}

const ComparisonButton = ({ data }: ComparisonButtonProps) => {
  const { onAddOrRemoveProductCompare } = useProductComparison()
  const compareList = useSelector(selectProductComparison)
  const translations = useSelector(selectTranslations)
  const isCompare = !!compareList.includes(Number(data.id))

  const onClickComparison = async (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    try {
      await onAddOrRemoveProductCompare(data)
    } catch (error) {
      console.error('Error while comparing products', error)
    }
  }
  return (
    <div onClick={onClickComparison} className='comparison-btn cursor-pointer'>
      <FormCheck
        readOnly
        checked={isCompare}
        label={translations.compare || 'Compare'}
        onClick={onClickComparison}
      />
    </div>
  )
}

export default ComparisonButton
