/* eslint-disable camelcase */
import { Auth } from 'aws-amplify'
import { useMyUserProfileLazyQuery } from 'services/user/user.generated'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setUser } from './user.reducer'

export const useInitUser = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [fetch] = useMyUserProfileLazyQuery()
  useEffect(() => {
    const getUser = async () => {
      try {
        await Auth.currentAuthenticatedUser({
          bypassCache: true,
        })
        const { data } = await fetch()
        dispatch(
          setUser({
            ...data?.getUserMetadata,
            ...data?.getUserProfile,
          }),
        )
      } catch (error) {
        console.error('useInitUser', error)
      }

      const preSignInUrl = localStorage.getItem('preSignInUrl')
      if (preSignInUrl) {
        localStorage.removeItem('preSignInUrl')
        navigate(preSignInUrl)
      }
    }
    getUser()
  }, [])
}
