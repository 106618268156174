import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MyUserProfileQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MyUserProfileQuery = { __typename?: 'Query', getUserProfile?: { __typename?: 'UserProfile', email?: string | null, lastLoginAt?: string | null, name?: string | null, role?: Types.UserRole | null, salesRepresentativeId?: string | null, userId?: string | null } | null, getUserMetadata?: { __typename?: 'UserMetadata', isDashboardAccessible?: boolean | null, hasSaleRepresentativeId?: boolean | null } | null };


export const MyUserProfileDocument = gql`
    query MyUserProfile {
  getUserProfile {
    email
    lastLoginAt
    name
    role
    salesRepresentativeId
    userId
  }
  getUserMetadata {
    isDashboardAccessible
    hasSaleRepresentativeId
  }
}
    `;

/**
 * __useMyUserProfileQuery__
 *
 * To run a query within a React component, call `useMyUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyUserProfileQuery(baseOptions?: Apollo.QueryHookOptions<MyUserProfileQuery, MyUserProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyUserProfileQuery, MyUserProfileQueryVariables>(MyUserProfileDocument, options);
      }
export function useMyUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyUserProfileQuery, MyUserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyUserProfileQuery, MyUserProfileQueryVariables>(MyUserProfileDocument, options);
        }
export function useMyUserProfileSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MyUserProfileQuery, MyUserProfileQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MyUserProfileQuery, MyUserProfileQueryVariables>(MyUserProfileDocument, options);
        }
export type MyUserProfileQueryHookResult = ReturnType<typeof useMyUserProfileQuery>;
export type MyUserProfileLazyQueryHookResult = ReturnType<typeof useMyUserProfileLazyQuery>;
export type MyUserProfileSuspenseQueryHookResult = ReturnType<typeof useMyUserProfileSuspenseQuery>;
export type MyUserProfileQueryResult = Apollo.QueryResult<MyUserProfileQuery, MyUserProfileQueryVariables>;