import { PAGE_IDS, TRANSLATIONS } from 'config/constants'
import { deleteDatabase } from 'database/dbFunctions'
import { saveCurrentCountryLangDb } from 'database/queries/country'
import Dexie from 'dexie'
import { useGlobalModalContext } from 'features/Modal/GlobalModal'
import useNavigation from 'hooks/useNavigation'
import { useEffect, useState } from 'react'
import { Button, Card, Dropdown, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { resetComparison } from 'redux/comparison/reducer'
import { deleteAllDownload } from 'redux/download/download.hook'
import {
  selectCountries,
  selectCurrentCountry,
  selectCurrentLanguage,
  selectTranslations,
} from 'redux/selector'
import { CountryItemType, CurrentCountryType, CurrentLanguageType } from 'types'

type CountryStateType = {
  currentCountryObj: CurrentCountryType
  currentLanguage: CurrentLanguageType
}

const ChangeCountryBlock = () => {
  const dispatch = useDispatch()
  const { onDynamicNavigate } = useNavigation()
  const { showModal, hideModal } = useGlobalModalContext()

  const translations = useSelector(selectTranslations)
  const countries = useSelector(selectCountries)
  const currentCountry = useSelector(selectCurrentCountry)
  const currentLanguage = useSelector(selectCurrentLanguage)

  const [country, setCountry] = useState<CountryStateType>({
    currentCountryObj: currentCountry ?? {
      name: '',
      code: '',
      languages: [],
    },
    currentLanguage: currentLanguage ?? { languageName: '', languageCode: '' },
  })

  const changeBtn = translations?.change ?? TRANSLATIONS.change
  const countryLanguageSettings =
    translations?.country_and_language_settings ?? TRANSLATIONS.countryAndLanguageSettings
  const changeCountry = translations?.change_the_country ?? TRANSLATIONS.changeTheCountry
  const changeLanguage = translations?.change_the_language ?? TRANSLATIONS.changeTheLanguage
  const doYouWantToChangeSettings =
    translations?.do_you_want_to_change_settings ?? TRANSLATIONS.doYouWantToChangeSettings

  useEffect(() => {
    if (currentCountry && currentLanguage) {
      setCountry({
        currentCountryObj: currentCountry,
        currentLanguage: currentLanguage,
      })
    }
  }, [currentCountry, currentLanguage])

  function onCountryChange(selectedCountry: CountryItemType) {
    const currentCountryObj = {
      name: selectedCountry.name,
      code: selectedCountry.code,
      languages: selectedCountry.languages,
    }
    const currentLanguage = selectedCountry.languages[0]
    setCountry({ currentCountryObj, currentLanguage })
  }

  function onLanguageChange(selectedLanguage: CurrentLanguageType) {
    const currentLanguage = selectedLanguage
    setCountry((prevCountry) => ({ ...prevCountry, currentLanguage }))
  }

  const resetDbApp = async () => {
    const databaseNames = await Dexie.getDatabaseNames()
    await Promise.all(databaseNames.map(deleteDatabase))
  }

  const onSaveChangeCountryLanguage = async () => {
    const { currentCountryObj, currentLanguage } = country
    if (currentCountryObj && currentLanguage) {
      try {
        await resetDbApp()
        await deleteAllDownload(dispatch)
        await saveCurrentCountryLangDb(currentCountryObj, currentLanguage)
        dispatch(resetComparison())
        onDynamicNavigate(PAGE_IDS.BUSINESS_UNIT_ENTRY)
        setTimeout(() => {
          window.location.reload()
        }, 200);
      } catch (error) {
        console.error('fail to change country', error)
      }
    }
  }

  const onShowModalConfirm = () => {
    showModal('CONFIRM_MODAL', {
      showModal: true,
      message: doYouWantToChangeSettings,
      handleClose: hideModal,
      handleSave: onSaveChangeCountryLanguage,
    })
  }
  return (
    <Card className='change-country-block setting-card'>
      <Row>
        <h6 className='settings-heading'>
          <i className='karcher-icon settings-icons'>&#xe842;</i>
          {countryLanguageSettings}
        </h6>
      </Row>

      <Row>
        <p className='settings-caption'>{changeCountry}</p>
        <Dropdown className='mt-2'>
          <Dropdown.Toggle className='w-100'>{country.currentCountryObj.name}</Dropdown.Toggle>
          <Dropdown.Menu>
            {countries.map((country) => (
              <Dropdown.Item onClick={() => onCountryChange(country)} key={country.name}>
                {country.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Row>

      <Row className='mt-2'>
        <p className='settings-caption'>{changeLanguage}</p>
        <Dropdown className='mt-2'>
          <Dropdown.Toggle className='w-100'>
            {country.currentLanguage.languageName}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {country.currentCountryObj.languages.map((language) => (
              <Dropdown.Item onClick={() => onLanguageChange(language)} key={language.languageCode}>
                {language.languageName}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Row>

      <div className='mb-2'>
        <Button
          id='onboarding-btn-change-country'
          className='app-button my-4'
          onClick={onShowModalConfirm}
        >
          {changeBtn.toUpperCase()}
        </Button>
      </div>
    </Card>
  )
}

export default ChangeCountryBlock
