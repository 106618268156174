import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetProductComparisonQueryVariables = Types.Exact<{
  input: Types.GetProductComparisonInput;
}>;


export type GetProductComparisonQuery = { __typename?: 'Query', getProductComparison?: { __typename?: 'ProductComparison', productIds: Array<number | null>, country: string, language: string } | null };

export type SaveProductComparisonMutationVariables = Types.Exact<{
  input: Types.SaveProductComparisonInput;
}>;


export type SaveProductComparisonMutation = { __typename?: 'Mutation', saveProductComparison?: boolean | null };


export const GetProductComparisonDocument = gql`
    query getProductComparison($input: GetProductComparisonInput!) {
  getProductComparison(input: $input) {
    productIds
    country
    language
  }
}
    `;

/**
 * __useGetProductComparisonQuery__
 *
 * To run a query within a React component, call `useGetProductComparisonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductComparisonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductComparisonQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProductComparisonQuery(baseOptions: Apollo.QueryHookOptions<GetProductComparisonQuery, GetProductComparisonQueryVariables> & ({ variables: GetProductComparisonQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductComparisonQuery, GetProductComparisonQueryVariables>(GetProductComparisonDocument, options);
      }
export function useGetProductComparisonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductComparisonQuery, GetProductComparisonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductComparisonQuery, GetProductComparisonQueryVariables>(GetProductComparisonDocument, options);
        }
export function useGetProductComparisonSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetProductComparisonQuery, GetProductComparisonQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetProductComparisonQuery, GetProductComparisonQueryVariables>(GetProductComparisonDocument, options);
        }
export type GetProductComparisonQueryHookResult = ReturnType<typeof useGetProductComparisonQuery>;
export type GetProductComparisonLazyQueryHookResult = ReturnType<typeof useGetProductComparisonLazyQuery>;
export type GetProductComparisonSuspenseQueryHookResult = ReturnType<typeof useGetProductComparisonSuspenseQuery>;
export type GetProductComparisonQueryResult = Apollo.QueryResult<GetProductComparisonQuery, GetProductComparisonQueryVariables>;
export const SaveProductComparisonDocument = gql`
    mutation saveProductComparison($input: SaveProductComparisonInput!) {
  saveProductComparison(input: $input)
}
    `;
export type SaveProductComparisonMutationFn = Apollo.MutationFunction<SaveProductComparisonMutation, SaveProductComparisonMutationVariables>;

/**
 * __useSaveProductComparisonMutation__
 *
 * To run a mutation, you first call `useSaveProductComparisonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveProductComparisonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveProductComparisonMutation, { data, loading, error }] = useSaveProductComparisonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveProductComparisonMutation(baseOptions?: Apollo.MutationHookOptions<SaveProductComparisonMutation, SaveProductComparisonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveProductComparisonMutation, SaveProductComparisonMutationVariables>(SaveProductComparisonDocument, options);
      }
export type SaveProductComparisonMutationHookResult = ReturnType<typeof useSaveProductComparisonMutation>;
export type SaveProductComparisonMutationResult = Apollo.MutationResult<SaveProductComparisonMutation>;
export type SaveProductComparisonMutationOptions = Apollo.BaseMutationOptions<SaveProductComparisonMutation, SaveProductComparisonMutationVariables>;