import { Auth } from 'aws-amplify'
import { useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { selectUser } from 'redux/user/user.selector'
import { useGlobalModalContext } from 'features/Modal/GlobalModal'

const UserProfile = () => {
  const { showModal, hideModal } = useGlobalModalContext()

  const user = useSelector(selectUser)
  const onLogout = async () => {
    try {
      showModal('LOADING_MODAL', undefined)
      const currentUrl = window.location.pathname
      localStorage.setItem('preSignInUrl', currentUrl)
      await Auth.signOut()
    } catch (error) {
      hideModal()
    }
  }

  function calculateTransform() {
    const element = document.getElementById('login-wrapper')
    // Get the element's width
    if (!element) return

    const rect = element.getBoundingClientRect()
    if (rect.right > window.innerWidth) {
      const overflowRight = rect.right - window.innerWidth
      element.style.left = `calc(50% - ${overflowRight}px)`
    }
  }

  useEffect(() => {
    calculateTransform()
  }, [])

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <p className='title-text'>
        <span className='ak-bold'>Name: </span> {user?.name}
      </p>
      <p className='title-text'>
        <span className='ak-bold'>Email: </span> {user?.email}
      </p>
      <Button variant='light' className='logout-button' onClick={onLogout}>
        {/* TODO: add translation key */}
        LOGOUT
      </Button>
    </div>
  )
}

export default UserProfile
