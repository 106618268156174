import { Table } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { ProductDetailItemType } from 'types'
import { TRANSLATIONS } from 'config/constants'
import { selectTranslations } from 'redux/selector'
import useNavigation from 'hooks/useNavigation'
import DCAImage from 'components/DCAImage'
import { useHeaderContext } from 'components/Page/headerContext'
import { useProductComparison } from '../hooks/useProductComparison'

type ImagesSectionProps = {
  productCompareDetails: ProductDetailItemType[]
}

const ImagesSection = ({ productCompareDetails }: ImagesSectionProps) => {
  const { onDynamicNavigate } = useNavigation()
  const { deleteProductFromComparison } = useProductComparison()
  const translations = useSelector(selectTranslations)

  const { headerHeight } = useHeaderContext()
  const orderNumberTxt = translations.order_number ?? TRANSLATIONS.orderNumber
  const grossPriceNumber = translations.gross_priceNumber
    ? translations.gross_priceNumber
    : 'Price Gross'
  const netPriceNumber = translations.net_priceNumber ? translations.net_priceNumber : 'Price Net'
  const removeTxt = translations.remove ?? TRANSLATIONS.remove

  const onClickRemove = async (id: number) => {
    deleteProductFromComparison(id)
  }

  const renderListProductInfo = () => {
    return productCompareDetails.map((productDetail) => {
      const imageUrl = productDetail?.images[0]?.urls?.find((img) => img.type === 'full')
      return (
        <td width='20%' key={productDetail.id}>
          <p className='export-text cursor-pointer' onClick={() => onClickRemove(productDetail.id)}>
            <i className='karcher-icon'>&#xe831;</i> {removeTxt}
          </p>
          <div className='export-text cursor-pointer'>
            <DCAImage
              className='product-compare-image'
              type='cardImage'
              src={imageUrl?.url ?? ''}
              onClick={() => {
                onDynamicNavigate(productDetail.id)
              }}
            />
          </div>

          <div className='ak-bold'>{productDetail?.name}</div>
          <div className='ak-bold'>
            {orderNumberTxt}: {productDetail?.partnumberFormatted}
          </div>
          {productDetail?.priceNetFormatted && (
            <div className='ak-bold'>
              {netPriceNumber}: {productDetail?.priceNetFormatted}
            </div>
          )}
          {productDetail?.priceGrossFormatted && (
            <div className='ak-bold'>
              {grossPriceNumber}: {productDetail?.priceGrossFormatted}
            </div>
          )}
        </td>
      )
    })
  }

  if (productCompareDetails.length === 0) return <></>

  return (
    <div className='table-container' style={{ position: 'sticky', top: headerHeight, zIndex: 1 }}>
      <Table className='ak-table table-borderless'>
        <tbody>
          <tr>
            <td width='20%'></td>
            {renderListProductInfo()}
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

export default ImagesSection
