import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { getUserAccessToken } from 'redux/user/user.util'

const createHttpLink = (uri: string, headers: Record<string, string> = {}) => {
  return new HttpLink({
    uri,
    headers,
  })
}

const createHeaders = async (isAuth: boolean, headers: Record<string, string>) => {
  if (!isAuth) {
    return {
      ...headers,
      'X-Api-Key': process.env.REACT_APP_NEW_GRAPHQL_API_KEY ?? '',
    }
  }

  const token = await getUserAccessToken()
  return {
    ...headers,
    Authorization: token ? `Bearer ${token}` : '',
  }
}

const createAuthLink = (isAuth: boolean) => {
  return setContext(async (_, { headers }) => {
    const newHeaders = await createHeaders(isAuth, headers)
    return { headers: newHeaders }
  })
}

const createApolloClient = (uri?: string, isAuth: boolean = true) => {
  if (!uri) {
    throw new Error('uri is required')
  }
  const httpLink = createHttpLink(uri)
  const link = ApolloLink.from([createAuthLink(isAuth), httpLink])

  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
  })
}

export const client = createApolloClient(process.env.REACT_APP_NEW_GRAPHQL_API_URL)

export const publicClient = createApolloClient(process.env.REACT_APP_NEW_GRAPHQL_API_URL, false)
