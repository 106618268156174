import { toast } from 'react-toastify'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { FavoriteContentType } from 'types'
import { ALL_FOLDER, LAYOUTS, PAGE_TYPES, TRANSLATIONS } from 'config/constants'
import { useSelector } from 'redux/hooks/useSelector'
import { selectAppOnline, selectStructure, selectTranslations } from 'redux/selector'
import { selectFavoritesContent } from 'redux/selector/favoritesSelector'
import useNavigation from 'hooks/useNavigation'
import { createCombinedPDF } from 'features/NewPDF/CombinedPDF/CombinedPDF'
import { createProductShortPDF } from 'features/NewPDF/ProductShortPDF/ProductShortPDF'
import { useProduct } from 'features/Product/hooks/useProduct'
import FavFolderTree from './FavFolderTree'
import { FavoritesList } from './FavoritesList'
import 'styles/pages/favorites.scss'

const filterAndSortFavoriteData = (folderID: string, favoriteContent: FavoriteContentType[]) => {
  const favoriteData =
    folderID === ALL_FOLDER.id
      ? [...favoriteContent]
      : favoriteContent.filter((item) => item.folderID === folderID)

  return favoriteData.sort((a, b) => a.position - b.position)
}

const mapFavoriteData = (item: FavoriteContentType) => {
  const baseData = { type: item.type, pageId: item.pageId, key: item.id }
  if (item.type === PAGE_TYPES.COMPARISON) {
    const compareIds = JSON.parse(item?.metadata ?? '[]')
    return { ...baseData, compareIds }
  }
  return baseData
}

const FavoritesUI = () => {
  const { onGoBack } = useNavigation()
  const { onGetProductDetails } = useProduct()

  const translations = useSelector(selectTranslations)
  const favoriteContent = useSelector(selectFavoritesContent)
  const isAppOnline = useSelector(selectAppOnline)
  const structure = useSelector(selectStructure) || []

  const myFavoritesTitle = translations.my_favorites ?? TRANSLATIONS.myFavorites
  const backToPreviousPage = translations.back_to_previous_page ?? TRANSLATIONS.backToPreViousPage

  const handleExportShortPDFs = async (folderID: string) => {
    try {
      const favoriteData = filterAndSortFavoriteData(folderID, favoriteContent)
      const exportedPDFData = favoriteData
        .filter((item) => item.type === LAYOUTS.PRODUCT_DETAILS)
        .map((i) => ({
          type: LAYOUTS.PRODUCT_DETAILS_SHORT,
          pageId: i.pageId,
          key: i.id,
        }))

      const toastMessage = !exportedPDFData.length
        ? 'No products to export short'
        : 'Loading PDF...'
      const toastId = toast(toastMessage, { autoClose: false })

      if (exportedPDFData.length) {
        const products = await onGetProductDetails(exportedPDFData.map((i) => i.pageId))
        createProductShortPDF({ products, translations })
      }

      setTimeout(() => {
        toast.dismiss(toastId)
      }, 1000)
    } catch (error) {
      console.error('handleExportShortPDFs', error)
    }
  }

  const handleExportContentPDF = (favoriteData: FavoriteContentType[]) => {
    const exportedPDFData = favoriteData.map(mapFavoriteData)
    createCombinedPDF(exportedPDFData, {
      isAppOnline,
      translations,
      structure,
    })
  }

  const handleExportFolderPDFs = (folderID: string) => {
    const favoriteData = filterAndSortFavoriteData(folderID, favoriteContent)
    handleExportContentPDF(favoriteData)
  }

  const renderHeader = () => (
    <Row>
      <Col md='3'>
        <h1>{myFavoritesTitle}</h1>
      </Col>
    </Row>
  )

  const renderFavorites = () => (
    <Row className='mt-4'>
      <Col md='4'>
        <FavFolderTree
          onActiveFolderData={() => {}}
          onExportFolder={handleExportFolderPDFs}
          onExportFolderShort={handleExportShortPDFs}
        />
      </Col>
      <Col md='8' className='favorite-content'>
        <FavoritesList onExportItem={handleExportContentPDF} />
      </Col>
    </Row>
  )

  return (
    <Container className='favorites-container' fluid>
      <Container className='py-4'>
        {renderHeader()}

        {renderFavorites()}
      </Container>
    </Container>
  )
}

export default FavoritesUI
