import { useState } from 'react'
import { Card, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { AccessoryItemType } from 'types'
import { getPageContentWithCallback } from 'redux/reducers/pageContentReducer'
import { selectTranslations } from 'redux/selector'
import { useGlobalModalContext } from 'features/Modal/GlobalModal'
import ComparisonButton from 'components/ComparisonButton'
import DCAImage from 'components/DCAImage'
import 'styles/pages/productList.scss'

type AccessoryProps = {
  accessory: AccessoryItemType
  onGoNextPage: (pageId: number) => void
}

const Accessory = ({ accessory, onGoNextPage }: AccessoryProps) => {
  const translations = useSelector(selectTranslations)
  const [favoriteIconLoading, setLoading] = useState(false)
  const { showModal, hideModal } = useGlobalModalContext()
  const dispatch = useDispatch()

  const onGoProductDetail = () => {
    onGoNextPage(accessory.id)
  }

  const onAddProductToFavorite = ({ id, name }: { id: number; name: string }) => {
    setLoading(true)
    dispatch(
      getPageContentWithCallback({
        pageId: id,
        async onSuccess(pageContent) {
          setLoading(false)
          showModal('FAVORITES_MODAL', {
            showModal: true,
            itemName: name,
            pageContent: pageContent,
            handleClose: hideModal,
          })
        },
        onFailure() {
          setLoading(false)
        },
      }),
    )
  }

  return (
    <Card className='accessory cursor-pointer' onClick={onGoProductDetail}>
      <ComparisonButton data={accessory} />

      <span
        tabIndex={0}
        role='button'
        className='favorite-form'
        onClick={(event) => {
          onAddProductToFavorite({ id: parseInt(accessory.id.toString()), name: accessory.name })
          event.stopPropagation()
        }}
      >
        <div className='horizontal-p'>
          {favoriteIconLoading ? (
            <Spinner className='favorite-icon'style={{marginBottom: 14}} size='sm'/>
          ) : (
            <p className='karcher-icon'>&#xe843;</p>
          )}
          <p className='favorite-text'>{translations.add_to_fav ?? 'Add to favourites'}</p>
        </div>
      </span>
      <DCAImage variant='top' type='cardImage' src={accessory.image} className='accessory-image' />
      <Card.Text className='accessory-name'>{accessory.name}</Card.Text>
    </Card>
  )
}

export default Accessory
