import 'react-tooltip/dist/react-tooltip.css'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { TRANSLATIONS } from 'config/constants'
import { selectTranslations } from 'redux/selector'

const DownloadBlockWeb = () => {
  const translations = useSelector(selectTranslations)

  const { download: downloadTitle = TRANSLATIONS.download } = translations || {}

  const onDownloadDesktop = () => {
    const desktopDownloadUrl = process.env.REACT_APP_WINDOWS_APP_SOURCE_URL
    if (desktopDownloadUrl) {
      window.open(desktopDownloadUrl, '_blank')
    }
  }

  return (
    <Card className='download-block setting-card'>
      <h6 className='settings-heading'>
        <i className='karcher-icon settings-icons'>&#xe851;</i>
        {downloadTitle}
      </h6>

      <p className='settings-caption' style={{ fontSize: 10 }}>
        Tu use the offline mode on your laptop device, please download the desktop app.
      </p>

      <Row className='mt-4'>
        <Col>
          <Button className='app-button' onClick={onDownloadDesktop}>
            DESKTOP APP DOWNLOAD
          </Button>
        </Col>
      </Row>
    </Card>
  )
}

export default DownloadBlockWeb
