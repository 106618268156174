import { Button, Modal } from 'react-bootstrap'
import 'styles/modal/confirmModal.scss'

export type OptionModalProps = {
  showModal: boolean
  message: string
  heading?: string
  leftButtonText: string
  rightButtonText: string
  onRightClick: () => void
  onLeftClick: () => void
  handleClose: () => void
}

const OptionModal = (props: OptionModalProps): React.ReactElement => {
  const {
    showModal,
    message,
    heading,
    leftButtonText,
    rightButtonText,
    onLeftClick,
    onRightClick,
    handleClose,
  } = props

  const handleLeftClick = () => {
    onLeftClick()
    handleClose()
  }
  const handleRightClick = () => {
    onRightClick()
    handleClose()
  }

  return (
    <Modal
      show={showModal}
      onEscapeKeyDown={() => {}}
      size='sm'
      className='confirm-modal'
      backdrop='static'
      clo
    >
      <Modal.Header>
        <Modal.Title className='confirm-modal-heading'>{heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>{message}</div>
      </Modal.Body>
      <Modal.Footer>
        <div className='modal-buttons p-3'>
          <Button variant='secondary' className='text-uppercase' onClick={handleLeftClick}>
            {leftButtonText}
          </Button>
          <Button variant='primary' className='text-uppercase' onClick={handleRightClick}>
            {rightButtonText}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default OptionModal
