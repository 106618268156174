import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetProductListByRootlineQueryVariables = Types.Exact<{
  input: Types.GetProductListByRootLineInput;
  pagination: Types.PaginationInput;
}>;


export type GetProductListByRootlineQuery = { __typename?: 'Query', getProductListByRootline?: { __typename?: 'GetProductListByRootlineResponse', export?: string | null, year?: number | null, country?: string | null, language?: string | null, createdAt?: string | null, productGroupData?: string | null, filterableItems?: { __typename?: 'FilterableItems', material?: { __typename?: 'AccessoryFilter', label?: string | null, name?: string | null, filterableValues?: Array<{ __typename?: 'AccessoryFilterableValue', unit?: string | null, value?: string | null } | null> | null } | null, standardNominalSize?: { __typename?: 'AccessoryFilter', label?: string | null, name?: string | null, filterableValues?: Array<{ __typename?: 'AccessoryFilterableValue', unit?: string | null, value?: string | null } | null> | null } | null } | null, data?: Array<{ __typename?: 'Product', id?: number | null, name?: string | null, productType?: string | null, businessUnit?: string | null, partnumber?: string | null, partnumberFormatted?: string | null, priceNet?: string | null, priceNetFormatted?: string | null, priceGross?: string | null, priceGrossFormatted?: string | null, techdata?: Array<{ __typename?: 'TechData', name?: string | null, label?: string | null, value?: string | null, unit?: string | null } | null> | null, images?: Array<{ __typename?: 'Image', title?: string | null, type?: string | null, urls?: Array<{ __typename?: 'URL', type?: string | null, url?: string | null } | null> | null } | null> | null, texts?: Array<{ __typename?: 'Text', value?: string | null, type?: string | null } | null> | null } | null> | null } | null };


export const GetProductListByRootlineDocument = gql`
    query getProductListByRootline($input: GetProductListByRootLineInput!, $pagination: PaginationInput!) {
  getProductListByRootline(input: $input, pagination: $pagination) {
    export
    year
    country
    language
    createdAt
    productGroupData
    filterableItems {
      material {
        filterableValues {
          unit
          value
        }
        label
        name
      }
      standardNominalSize {
        filterableValues {
          unit
          value
        }
        label
        name
      }
    }
    data {
      id
      name
      productType
      businessUnit
      partnumber
      partnumberFormatted
      priceNet
      priceNetFormatted
      priceGross
      priceGrossFormatted
      techdata {
        name
        label
        value
        unit
      }
      images {
        title
        type
        urls {
          type
          url
        }
      }
      texts {
        value
        type
      }
    }
  }
}
    `;

/**
 * __useGetProductListByRootlineQuery__
 *
 * To run a query within a React component, call `useGetProductListByRootlineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductListByRootlineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductListByRootlineQuery({
 *   variables: {
 *      input: // value for 'input'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetProductListByRootlineQuery(baseOptions: Apollo.QueryHookOptions<GetProductListByRootlineQuery, GetProductListByRootlineQueryVariables> & ({ variables: GetProductListByRootlineQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductListByRootlineQuery, GetProductListByRootlineQueryVariables>(GetProductListByRootlineDocument, options);
      }
export function useGetProductListByRootlineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductListByRootlineQuery, GetProductListByRootlineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductListByRootlineQuery, GetProductListByRootlineQueryVariables>(GetProductListByRootlineDocument, options);
        }
export function useGetProductListByRootlineSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetProductListByRootlineQuery, GetProductListByRootlineQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetProductListByRootlineQuery, GetProductListByRootlineQueryVariables>(GetProductListByRootlineDocument, options);
        }
export type GetProductListByRootlineQueryHookResult = ReturnType<typeof useGetProductListByRootlineQuery>;
export type GetProductListByRootlineLazyQueryHookResult = ReturnType<typeof useGetProductListByRootlineLazyQuery>;
export type GetProductListByRootlineSuspenseQueryHookResult = ReturnType<typeof useGetProductListByRootlineSuspenseQuery>;
export type GetProductListByRootlineQueryResult = Apollo.QueryResult<GetProductListByRootlineQuery, GetProductListByRootlineQueryVariables>;