import { universal } from 'database/db'
import { v4 as uuidv4 } from 'uuid'
import { ProductCompareType } from 'types'

/**
 * @deprecated the product comparison db is now deprecated, only support read, no more write
 * @param productCompare
 * if comparisonData exists, update the products array with the new productCompare array
 * if comparisonData does not exist, create a new entry with the new productCompare array
 * @returns
 */
export async function saveProductToCompareDb(productCompare: ProductCompareType[]) {
  try {
    const comparisonData = await universal.dcaProductCompare.orderBy('id').first()

    if (comparisonData) {
      comparisonData.products = productCompare
      await universal.dcaProductCompare.put(comparisonData)
    } else {
      await universal.dcaProductCompare.put({ id: uuidv4(), products: productCompare })
    }

    return { save: true }
  } catch (error) {
    console.error('saveProductToCompareDb err', error)
    throw error
  }
}

/**
 * @deprecated
 */
export const getProductCompareDb = async (): Promise<ProductCompareType[]> => {
  try {
    const comparisonData = await universal.dcaProductCompare.orderBy('id').first()
    if (comparisonData) {
      return comparisonData.products
    }
    return []
  } catch (error) {
    console.error('getProductCompareDb error:', error)
    throw error
  }
}

export const deleteProductCompareDb = async () => {
  try {
    await universal.dcaProductCompare.clear()
  } catch (error) {
    console.error('deleteProductCompareDb error:', error)
    throw error
  }
}
