import { PageContentResponseType, ProductListItemType, StructureItemType } from 'types'
import { splitArrayIntoChunks } from 'config/utils/CommonFunction'
import { getBreadcrumbData, printDocument, tr } from '../pdfHelper'
import { getCustomHTML } from '../pdfLayouts'
import { RibbonData, TranslationsPDFType } from '../types'

export const toProductListHTML = (
  products: ProductListItemType[],
  translations: {
    [key: string]: string
  },
) => {
  const imgArr = products.map((productData) => productData?.images?.[0]?.urls?.[2]?.url ?? '')

  const productChunks = splitArrayIntoChunks(products, 3)
  const imagesChunks = splitArrayIntoChunks(imgArr, 3)

  let str = ''
  let showPriceRemark = false
  productChunks.forEach((rowItem, rowIndex) => {
    str += '<div class="page-break-auto product-row">'
    rowItem.forEach((colItem, colIndex) => {
      if (colItem) {
        const imgUrl = imagesChunks[rowIndex][colIndex]
        str += `
        <div class="product-item">
          <img src="${imgUrl}" alt="" />
          <h4>${colItem.name}</h4>
          <div class="product-detail">${tr(translations, 'order_number', 'Order number')} ${
          colItem.partnumberFormatted
        }</div>`

        if (colItem.priceNetFormatted && colItem.priceGrossFormatted) {
          showPriceRemark = true
          str += `<div class="product-detail">${tr(translations, 'net_priceNumber', 'Netto')} ${
            colItem.priceNetFormatted
          }</div> <div class="product-detail">${tr(translations, 'gross_priceNumber', 'Gross')} ${
            colItem.priceGrossFormatted
          }</div>`
        }

        if (colItem.texts?.[0]?.value) {
          str += `<p class="product-item-description">${colItem.texts?.[0]?.value}</p>`
        }
        str += '</div>'
      }
    })

    str += '</div>'
  })

  const priceMarkHTML = showPriceRemark
    ? `<p style="width: 95%;">${tr(translations, 'price_remark')}</p>`
    : ''

  str = `<div class="product-list-wrap">
        ${str}
        ${priceMarkHTML}
        </div>`
  return str
}

export const createProductListPDF = (
  data: {
    pageContent: PageContentResponseType
    structure: StructureItemType[] | null
    products: ProductListItemType[]
    translations: TranslationsPDFType
    ribbonData: RibbonData
  },
  onDone?: () => void,
) => {
  const { pageContent, structure, products, translations, ribbonData } = data

  const breadCrumbData = getBreadcrumbData(pageContent.pageId, structure)
  const productHTML = toProductListHTML(products, translations)
  const htmlDOM = getCustomHTML({
    ribbonData,
    breadCrumbData,
    pageStyles: productListStyle,
    title: ribbonData?.headline,
  })

  const contentHTML = htmlDOM.getElementById('main')!
  contentHTML.innerHTML += productHTML
  printDocument(htmlDOM, onDone)
}

export const productListStyle = `
.ribbon-text {
  margin-top: 20px;
  padding: 20px;
  background-color: #FFED00;
}
.ribbon-text h1 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.product-list-wrap {
  width: 100%;
}
.product-row {
  padding-top: 30px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.product-item {
  width: calc(100% / 3);
  padding: 0px 10px;
  box-sizing: border-box;
}
.product-item-description {
  box-sizing: border-box;
  word-break: break-all;
}
.product-detail {
  font-size: 12px;
  font-weight: bold;
}
.product-item h4 {
  font-size: 14px;
}
.product-item h5 {
  font-size: 12px;
  font-weight: bold;
  margin: 2px 0;
}
.product-item img {
  height: 150px;
  width: 100%;
  object-fit: contain;
}
.product-item .product-group-img {
  height: auto;
  max-height: 150px;
  width: 100%;
  object-fit: contain;
}
.product-item p {
  margin-bottom: 0;
  font-size: 12px;
}
`
