import { deleteProductCompareDb, getProductCompareDb } from 'database/queries/comparison'
import { Dispatch } from 'redux'
import { addProductsToCompareBucket } from 'redux/comparison/reducer'

// TODO: delete this function after all users have updated the app
export const syncOldProductFromDexieToRedux = async (dispatch: Dispatch) => {
  const products = await getProductCompareDb()
  dispatch(addProductsToCompareBucket(products.map((product) => product.id)))
  await deleteProductCompareDb()
}
