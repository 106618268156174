import images from 'assets/images/images'
import Container from 'react-bootstrap/Container'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TRANSLATIONS } from 'config/constants'
import DCAImage from 'components/DCAImage'
import 'styles/pages/onboarding.scss'

interface StepProps {
  translations: { [key: string]: string }
  specialLanguage?: any
}

const DEFAULT_HEADING = 'Zurück zum Anfang'
const DEFAULT_DESCRIPTION =
  'Klicken Sie auf das Kärcher-Logo, um zum Startbildschirm zurück zu gelangen. Sie kommen so auch zurück zu den Bereichen Home & Garden sowie Professional.'

export function StepOne({ translations, specialLanguage }: StepProps) {
  const headingTxt =
    translations?.onboarding_root_heading ?? 'Welcome to the "Kärcher Programme" app'
  const contentTxt =
    translations?.onboarding_root_description ??
    'Become familiar with the most important functions of the "Kärcher Programme" app.'

  return (
    <Container lang="ru">
      <DCAImage src={images.logo} className='ak-logo' />
      <h5 className='onboarding-headline mt-4' {...specialLanguage}>{headingTxt}</h5>
      <div
        className='onboarding-description'
        {...specialLanguage}
        dangerouslySetInnerHTML={{
          __html: contentTxt,
        }}
      />
    </Container>
  )
}

export function StepTwo({ translations, specialLanguage }: StepProps) {
  const headingTxt = translations?.onboarding_logo_heading ?? DEFAULT_HEADING
  const contentTxt = translations?.onboarding_logo_description ?? DEFAULT_DESCRIPTION

  return (
    <Container>
      <div className='onboarding-headline' {...specialLanguage} >{headingTxt.toUpperCase()}</div>
      <div
        className='onboarding-description'
        {...specialLanguage}
        dangerouslySetInnerHTML={{
          __html: contentTxt,
        }}
      />
    </Container>
  )
}

export function StepThree({ translations, specialLanguage }: StepProps) {
  const headingTxt = translations?.onboarding_onlineOfflineIndicator_heading ?? DEFAULT_HEADING
  const contentTxt =
    translations?.onboarding_onlineOfflineIndicator_description ?? DEFAULT_DESCRIPTION

  return (
    <Container>
      <div className='onboarding-headline' {...specialLanguage}>
        {headingTxt.toUpperCase()}
        <FontAwesomeIcon className='icon-online' size='1x' icon={faCircle} />
        <FontAwesomeIcon className='icon-offline' size='1x' icon={faCircle} />
      </div>
      <div
        className='onboarding-description'
        {...specialLanguage}
        dangerouslySetInnerHTML={{
          __html: contentTxt,
        }}
      />
    </Container>
  )
}

export function StepFour({ translations, specialLanguage }: StepProps) {
  const headingTxt = translations?.onboarding_favorite_heading ?? DEFAULT_HEADING
  const contentTxt = translations?.onboarding_favorite_description ?? DEFAULT_DESCRIPTION

  return (
    <Container>
      <div className='onboarding-headline' {...specialLanguage}>
        <i className='karcher-icon'>&#xe844;</i> {headingTxt.toUpperCase()}
      </div>
      <div
        className='onboarding-description'
        {...specialLanguage}
        dangerouslySetInnerHTML={{
          __html: contentTxt,
        }}
      />
    </Container>
  )
}

export function StepFive({ translations, specialLanguage }: StepProps) {
  const headingTxt = translations?.onboarding_productCompare_heading ?? DEFAULT_HEADING
  const firstContentTxt =
    translations?.onboarding_productCompare_description1 ??
    'Klicken Sie auf das Kärcher-Logo, um zum Startbildschirm zurück zu gelangen.'
  const secondContentTxt =
    translations?.onboarding_productCompare_description2 ??
    'Sie kommen so auch zurück zu den Bereichen Home & Garden sowie Professional.'
  const compareTxt = translations?.compare ?? TRANSLATIONS.compare

  return (
    <Container>
      <div className='onboarding-headline' {...specialLanguage}>
        <i className='karcher-icon'>&#xe917;</i> {headingTxt.toUpperCase()}
      </div>
      <div
        className='onboarding-description'
        {...specialLanguage}
        dangerouslySetInnerHTML={{
          __html: firstContentTxt,
        }}
      />
      <p className='onboarding-description' {...specialLanguage}>
        <span className='checkbox' /> {compareTxt}
      </p>
      <div
        className='onboarding-description'
        {...specialLanguage}
        dangerouslySetInnerHTML={{
          __html: secondContentTxt,
        }}
      />
    </Container>
  )
}

export function StepSix({ translations, specialLanguage }: StepProps) {
  const headingTxt = translations?.onboarding_moreInformationRibbon_heading ?? DEFAULT_HEADING
  const contentTxt =
    translations?.onboarding_moreInformationRibbon_description ?? DEFAULT_DESCRIPTION

  return (
    <Container>
      <div className='onboarding-headline' {...specialLanguage}>{headingTxt.toUpperCase()}</div>
      <div
        className='onboarding-description'
        {...specialLanguage}
        dangerouslySetInnerHTML={{
          __html: contentTxt,
        }}
      />
    </Container>
  )
}

export function StepSeven({ translations, specialLanguage }: StepProps) {
  const headingTxt = translations?.onboarding_orderNumber_heading ?? DEFAULT_HEADING
  const contentTxt = translations?.onboarding_orderNumber_description ?? DEFAULT_DESCRIPTION

  return (
    <Container>
      <div className='onboarding-headline' {...specialLanguage}>{headingTxt.toUpperCase()}</div>
      <div
        className='onboarding-description'
        {...specialLanguage}
        dangerouslySetInnerHTML={{
          __html: contentTxt,
        }}
      />
    </Container>
  )
}

export function StepEight({ translations, specialLanguage }: StepProps) {
  const headingTxt = translations?.onboarding_export_heading ?? DEFAULT_HEADING
  const contentTxt = translations?.onboarding_export_description ?? DEFAULT_DESCRIPTION

  return (
    <Container>
      <div className='onboarding-headline' {...specialLanguage}>
        <i className='karcher-icon'>&#xe914;</i> {headingTxt.toUpperCase()}
      </div>
      <div
        className='onboarding-description'
        {...specialLanguage}
        dangerouslySetInnerHTML={{
          __html: contentTxt,
        }}
      />
    </Container>
  )
}

export function StepNine({ translations, specialLanguage}: StepProps) {
  const headingTxt = translations?.onboarding_download_heading ?? DEFAULT_HEADING
  const contentTxt = translations?.onboarding_download_description ?? DEFAULT_DESCRIPTION

  return (
    <Container>
      <div className='onboarding-headline' {...specialLanguage}>
        <i className='karcher-icon'>&#xe851;</i> {headingTxt.toUpperCase()}
      </div>
      <div
        className='onboarding-description'
        {...specialLanguage}
        dangerouslySetInnerHTML={{
          __html: contentTxt,
        }}
      />
    </Container>
  )
}

export function StepTen({ translations, specialLanguage }: StepProps) {
  const headingTxt = translations?.onboarding_changeLanguage_heading ?? DEFAULT_HEADING
  const contentTxt = translations?.onboarding_changeLanguage_description ?? DEFAULT_DESCRIPTION

  return (
    <Container>
      <div className='onboarding-headline' {...specialLanguage}>
        <i className='karcher-icon'>&#xe842;</i> {headingTxt.toUpperCase()}
      </div>
      <div
        className='onboarding-description'
        {...specialLanguage}
        dangerouslySetInnerHTML={{
          __html: contentTxt,
        }}
      />
    </Container>
  )
}

export function StepEleven({ translations, specialLanguage }: StepProps) {
  const headingTxt = translations?.onboarding_playOnboarding_heading ?? DEFAULT_HEADING
  const contentTxt = translations?.onboarding_playOnboarding_description ?? DEFAULT_DESCRIPTION

  return (
    <Container>
      <div className='onboarding-headline' {...specialLanguage}>
        <i className='karcher-icon'>&#xe802;</i> {headingTxt.toUpperCase()}
      </div>
      <div
        className='onboarding-description'
        {...specialLanguage}
        dangerouslySetInnerHTML={{
          __html: contentTxt,
        }}
      />
    </Container>
  )
}
