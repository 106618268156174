/* eslint-disable react/prop-types */
import images from 'assets/images/images'
import { LazyLoadImage, LazyLoadImageProps } from 'react-lazy-load-image-component'
import React, { useEffect, useState } from 'react'
import { CardImg, Image } from 'react-bootstrap'
import { isLocalImageExist, toLocalImagePath } from 'config/utils/CommonFunction'

type Props = {
  variant?: 'top' | 'bottom' | string
  type?: 'image' | 'cardImage' | 'lazyImage' | 'default'
  showEnlargedImage?: boolean
  toggleImageModal?: (showEnlargedImage: boolean, src: string) => void
  onClick?: () => void
} & React.ImgHTMLAttributes<HTMLImageElement> &
  LazyLoadImageProps

const ImageNotFound = ({ type = 'default' }: Props) => {
  if (type === 'lazyImage') {
    return <Image src={images.imageNotFound} className='ak-card-img' />
  }

  return <Image src={images.imageNotFound} className='card-wrapper-no-border' />
}

const DefaultImage = ({ src, ...restProps }: Props) => <img src={src} {...restProps} />

const ImageWrapper = ({
  type = 'default',
  showEnlargedImage = false,
  toggleImageModal,
  onClick,
  ...restProps
}: Props) => {
  const handleClick = () => {
    if (toggleImageModal && restProps.src) {
      toggleImageModal(true, restProps.src)
    }
  }

  const handleClickWrapper = () => {
    if (onClick) {
      onClick()
    } else if (showEnlargedImage) {
      handleClick()
    }
  }

  switch (type) {
    case 'image':
      return <Image onClick={handleClickWrapper} {...restProps} />

    case 'cardImage':
      return (
        <CardImg className='card-wrapper-no-border' onClick={handleClickWrapper} {...restProps} />
      )

    case 'lazyImage':
      return (
        <LazyLoadImage
          effect='blur'
          threshold={500}
          onClick={handleClickWrapper}
          {...(restProps as any)}
        />
      )
    default:
      return <DefaultImage {...restProps} />
  }
}

const DCAImage = (props: Props) => {
  const { src = '', ...rest } = props

  const [imageAuth, setImageAuth] = useState<string | undefined>(undefined)

  useEffect(() => {
    async function toImageUrl() {
      const isExist = await isLocalImageExist(src)
      if (isExist) {
        setImageAuth(toLocalImagePath(src))
      } else {
        setImageAuth(src)
      }
    }
    if (src) {
      toImageUrl()
    }
  }, [src])

  const onLoadError = () => {
    if (imageAuth !== images.imageNotFound) {
      setImageAuth(images.imageNotFound)
    }
  }

  if (!src) return <ImageNotFound {...props} />

  if (imageAuth) {
    return <ImageWrapper {...rest} src={imageAuth} onError={onLoadError} />
  }

  return null
}

export default React.memo(DCAImage)
