import { Maybe, Product, Url } from 'services/types'
import { ProductListItemType } from 'types'

// abc def xyz  --->   abc_def_xyz
export const replaceSpaceByDash = (str: string): string =>
  (str || '').toLowerCase().replaceAll(' ', '_')

export const replaceSlashByDoubleQuote = (str: string): string => {
  return str.replaceAll('\\"', '"')
}

export const formatSearchTerm = (str: string): string => {
  return str.replaceAll('\\', '').replaceAll('"', '\\"')
}

export const getImageUrl = (
  product: ProductListItemType | Product,
  type: 'full' = 'full',
): string => {
  if (!product || !product.images || !product.images[0] || !product.images[0].urls) return ''

  const firstImage = product.images[0]
  const listUrl = firstImage.urls as Url[]
  return listUrl.find((img) => img && img.type === type)?.url ?? ''
}
